.faq-main-img {
  background-color: #f8f8f8;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 5rem;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.55),
      rgba(0, 0, 0, 0.15)
    ),
    url(../img/FAQs.jpg);
}

@media only screen and (max-width: 480px) {
  .faq-main-img {
    margin-top: 4rem;
  }
  .top-img-text {
    position: relative;
    margin: 0 auto;
    max-width: 100rem;
    width: 100% !important;
    padding: 2.5rem 2rem !important;
  }

  .top-img-text h4 {
    width: 100% !important;
  }
}

.top-img-text h1 {
  color: #fff;
  font-weight: bold;
  width: 60%;
}
.top-img-text h4 {
  color: #fff;
  font-weight: bold;
  width: 60%;
}

.top-img-text {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 8.5rem 5rem;
}

/* Mobile Dimension */
@media only screen and (max-width: 768px) {
  .top-right-image-text {
    width: 100%;
  }
  .top-img-text h4 {
    width: 100% !important;
  }
}

.top-right-image-para {
  width: 380px;
  font-size: 21px;
}

#Question-colour {
  color: #f95738;
}

#contain-faqs {
  margin-bottom: 100px !important;
}

.container-head-text {
  font-weight: 800;
}

.Card.Header {
  background-color: #f95738 !important;
  border-radius: 0px !important;
}

.card-header:first-child {
  background-color: #fff !important;
  border: none !important;
  font-weight: bold;
  font-size: 1.5rem;
  border-top: 1px solid #f95738 !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  padding: 20px;
  cursor: pointer;
}

#acc-card-faqs {
  border: none !important;
  border-radius: none !important;
}

.general-faqs-text {
  display: flex;
  justify-content: center;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .general-faqs-text {
    display: inline-block;
    justify-content: center;
    font-weight: 500;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .top-img-text h1 {
    color: #fff;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }

  .top-img-text h4 {
    color: #fff;
    font-weight: bold;
    width: 60%;
    text-align: center;
  }
}

#Question-colour-2 {
  color: #f95738;
  margin-left: 5px;
}

.w-Card-child {
  word-spacing: 2px;
}

#footer {
  background-color: #f7f7f7;
  width: 100%;
  font-size: 1rem;
  transition: 0.25s;
}

#footer ul {
  list-style: none;
  padding: 0;
}

.footer-container {
  margin: 0 auto;
  max-width: 100rem;
  padding: 2.5rem 5rem;
}

.footer-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.footer-grid-up {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #cacaca;
}

.footer-details p {
  color: #f04217;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.footer-details svg {
  margin-right: 0.55rem;
}

.footer-details path {
  fill: #f04217;
}

.footer-details li {
  margin: 0.5rem 0;
}

.footer-details li a {
  position: relative;
  transition: 0.25s;
}

.footer-details li .contact-us {
  color: #000;
  font-weight: normal;
  cursor: pointer;
}

.footer-details li a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #f04217;
  transform-origin: bottom right;
  transform: scaleX(0);
  transition: transform 0.5s ease;
}

.footer-details li a:hover::before {
  transform-origin: bottom left;
  transform: scaleX(1);
}

.footer-details li a:hover {
  color: #000 !important;
}

.footer-media {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.25rem;
}

.footer-media ul {
  display: flex;
}
.footer-media li {
  padding-left: 3.5rem;
}
.footer-media li a {
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.25s;
  color: #3b3b3b;
}
.footer-media li a:hover {
  color: #f04217 !important;
}

/*** --- Media Queries --- ***/
/* Mobile Dimension */
@media only screen and (max-width: 480px) {
  .footer-container {
    padding: 2.5rem 2rem !important;
  }
  .footer-media li {
    padding: 0 1.3rem;
  }
}

/* Mobile Dimension & Tablet */
@media only screen and (max-width: 768px) {
  .footer-container {
    padding: 2.5rem 1.3rem;
  }
  .footer-grid-up {
    grid-template-columns: 1fr;
    padding-bottom: 1.5rem;
    border-bottom: none;
  }
  .footer-details {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #cacaca;
  }
  .footer-media {
    flex-direction: column;
    padding-top: 0.5rem;
  }
  .footer-media ul {
    margin-top: 1rem;
  }
}

/* Tablet Dimension */
@media only screen and (min-width: 480px) and (max-width: 768px) {
  .footer-container {
    padding: 2.5rem 3.5rem !important;
  }
}

/* Extra Dimension */
@media only screen and (max-width: 1188px) {
  .footer-container {
    padding: 2.5rem 2rem;
  }
  .footer-grid-up {
    column-gap: 1rem;
  }
}

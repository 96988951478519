 	.summary-bottom-btn{
background: var(--clr-blue) !important; 
 border-radius: 4px !important;  
	color: #fff !important;
	}
 .summary-container-hire{
  width: 100%;
 }
.summary-container-title-sub{ 
	padding: 1rem;
	width: 100%;
	height: 40px;
  background:   var(--clr-blue) !important; 
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-weight:light;
	font-size: 12px;
	color: #fff; 
}
#screen-wrapper-container{
	width: 100% !important;
}
.Hire-first-header{
 position: sticky;  
      top: 0; 
    width: 100%;
    height: 65px;
    max-height: 65px;
    padding:  48px;
    padding-top:  15px;
    background: var(--clr-blue); 
	  z-index: 3;  
	transition: .5s all;
    
}
.Hire-first-second{
 position: sticky;    
     top: 65px; 
    height: 40px;
    background-color: #fff;
    width: 100%;
    padding: 0 48px;
     z-index: 3;  
    box-shadow: 0 0 3px 3px #00000014;
    transition: .5s all;
}

.Hire-email-apply{
 width: 100%; 
 background-color: #fff;
 padding: 24px 30px;
  border: 2px solid #E7ECF2;
 box-shadow: none;
 /* margin-bottom: 1rem; */

}

#Total__Experience {
  background-color: #F7F9FA;
  padding: 30px;
  border-radius: 12px;
  margin: 35px;
}

/* Media queries for responsive design */

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  #Total__Experience {
    padding: 25px; /* Adjust padding for smaller screens */
    margin: 25px; /* Adjust margin for smaller screens */
  }
  .hire-apply-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 0px !important;
}
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  #Total__Experience {
    padding: 20px !important; /* Further adjust padding for smaller screens */
    margin: 20px !important; /* Further adjust margin for smaller screens */
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 480px) {
  #Total__Experience {
    padding: 15px !important; /* Further adjust padding for smallest screens */
    margin: 15px !important; /* Further adjust margin for smallest screens */
  }
}


/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .Hire-email-apply {
    padding: 20px 25px; /* Adjust padding for smaller screens */
    margin-bottom: 0.75rem; 
  }

    	.summary-bottom-btn{
background: var(--clr-blue) !important; 
 border-radius: 4px !important;  
	color: #fff !important;
	width: 100% !important;
   margin-bottom: 20px !important;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .Hire-email-apply {
    padding: 15px 20px; /* Further adjust padding for smaller screens */
    margin-bottom: 0.5rem; /* Further adjust margin for smaller screens */
  }

  	.summary-bottom-btn{
background: var(--clr-blue) !important; 
 border-radius: 4px !important;  
	color: #fff !important;
	width: 100% !important;
   margin-bottom: 20px !important;
	}

  .summary-main {
    padding: 1rem 0px !important;
}
.subheading {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    color: grey !important;
    font-family: "Poppins" !important;
}
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 480px) {
  .Hire-email-apply {
    padding: 10px 15px; /* Further adjust padding for smallest screens */
    margin-bottom: 0.25rem; /* Further adjust margin for smallest screens */
    border-width: 1px; /* Adjust border width for smallest screens */
  }
    	.summary-bottom-btn{
background: var(--clr-blue) !important; 
 border-radius: 4px !important;  
	color: #fff !important;
	width: 100% !important;
  margin-bottom: 20px !important;
	}

    .summary-main {
    padding: 1rem 0px !important;
}

.subheading {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    color: grey !important;
    font-family: "Poppins" !important;
}
}
.Hire-main-apply {
  background-color: #fff;
  padding: 24px 30px;
  border: 2px solid #E7ECF2;
  box-shadow: none;
  margin-bottom: 1rem;
}

/* Media queries for responsive design */

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .Hire-main-apply {
    padding: 20px 25px; /* Adjust padding for smaller screens */
    margin-bottom: 0.75rem; /* Adjust margin for smaller screens */
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .Hire-main-apply {
    padding: 15px 20px; /* Further adjust padding for smaller screens */
    margin-bottom: 0.5rem; /* Further adjust margin for smaller screens */
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 480px) {
  .Hire-main-apply {
    padding: 10px 15px; /* Further adjust padding for smallest screens */
    margin-bottom: 0.25rem; /* Further adjust margin for smallest screens */
    border-width: 1px; /* Adjust border width for smallest screens */
  }
}


.summary-job-details{
  font-weight: bold;
  font-size: large;
}

.job-title {
    font-weight: 600;
    margin-bottom: 4px;
}

.break-word-pre-line {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
}

 .Hire-form-control {
    padding-left: 59px;
}  

  .inputWithIcon input[type="email"] {
  width: 500px;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  margin-right: 20px !important;
}

 .inputWithIcon input[type="email"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

.inputWithIcon input[type="email"] {
  padding-left: 40px !important;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon i {
  position: absolute !important;
  left: 0 !important;
  top: 8px !important;
  padding: 9px 8px !important;
  color: #aaa !important;
  transition: 0.3s !important;
}

.inputWithIcon input[type="text"]:focus + i {
  color: dodgerBlue;
}

.inputWithIcon.inputIconBg i {
 background: var(--clr-blue); 
  color: #fff !important;
  /* padding: 6px 4px !important; */
  border-radius: 4px 0 0 4px !important;
}

.inputWithIcon.inputIconBg input[type="text"]:focus + i {
  color: #fff !important;
  background-color: var(--clr-blue)!important;
}

.email-apply-container-flex{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width:100% !important;
}

 

 

.inputIconBg { 
  border-radius: 5px; 
}

/* Media queries for responsive design */

@media (max-width: 768px) {
  .email-apply-container-flex {
    flex-direction: column;
    align-items: stretch;
  }

  .inputWithIcon {
    margin-bottom: 10px; /* Example spacing between elements */
  }
}

@media (max-width: 480px) {
  .inputWithIcon input[type="email"] {
    width: 100%; /* Full width for smaller screens */
  }
}
.Basic__Info{
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 20px;
    margin-bottom: 20px;
}

.HireForm-container{
	width: 100%;
	height: 100%
}

.Hire-first-second-main-container{ 
	width: 80%;
	margin: auto;
	margin-bottom: 2rem;
	/* margin: 1rem 15rem; */
  }  
.hire-apply-btn-container{
	display: flex;
	justify-content: flex-end;
	margin-right: 2rem;
}


 
a,
a:hover,
a:focus {
  text-decoration: none;
  transition: 0.5s;
  outline: none;
}

/* ------------ */

.card {
  /* box-shadow: 0px 4px 8px rgb(0 0 0 / 16%); */
  border-radius: 6px;
  overflow: hidden;
  /* margin-bottom: 30px; */
  background-color: #fff;
  border: none;
  
}
.card-body {
  /* padding: 30px; */
      /* border: 2px solid #E7ECF2; */
}
 
.form-submit {
  padding: 13px 30px;
  font-size: 15px;
  letter-spacing: 0.3px;
  font-weight: 400;
}
.kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.kb-file-upload {
  margin-bottom: 20px;
}
.file-upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 150px;
  position: relative;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}
.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-link {
  color: #475f7b;
  text-decoration: underline;
  margin-left: 3px;
}
.file-upload-box .file-link:hover {
  text-decoration: none;
}
.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.file-image {
  /* width: 130px;
    min-width: 130px;
    height: 85px;
    min-height: 85px; */
  width: 130px;
  height: 85px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}
.file-detail h6 {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}
.file-atc-box:last-child {
  margin-bottom: 0;
}
.hire-optional{
  font-weight:200;
  color: #2ecc71;
}
.hire-impotant{
  font-weight:200;
  color: #e74c3c;
}

/* .form-control.success input {
	border-color: #2ecc71;
}

.form-control.error input {
	border-color: #e74c3c;
} */


.Recruiting-tab-add{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Recruiting-tab-add-btn{
  color: #000;
}
.Recruiting-tab-add-btn :hover{
  color: #2ecc71;
}
.Recruiting-tab-add-btn1{
  color: #e74c3c;
}
.Recruiting-tab-add-btn1 :hover{
  color: #e74d3ca1;
}


 
 .job-title-locatiom{
  display: flex; 
  align-items: center;
 }

 .i-icon{
  display: flex;
  justify-content: center;
  align-items: center;
 }


 @media only screen and (max-width: 980px) {
.Hire-first-second-main-container {
    width: 100%;
    margin: auto;
    margin-bottom: 2rem; 
}
.addsteppercontainer {
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, .025), 0 0 16px rgba(0, 0, 0, .025), 0 0 32px rgba(0, 0, 0, .025), 0 0 64px rgba(0, 0, 0, .025);
    height: 100%;
    padding: 0px !important;
    width:100% !important;
}

.Recruiting__info, #Salary__Info {
    display: grid;
   grid-template-columns: 1fr ; 
    grid-gap: 20px !important;
}



#Total__Experience {
    background-color: #F7F9FA;
    padding: 30px;
    border-radius: 12px;
    margin: 0px !important;
}

  select { 
  width: 100% !important;
	border: 1px solid #D2D6DA;
		border-radius: 12px;
  display: inline-block;
  font: inherit; 
    padding: 16.5px  ; 
  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
}


/* Base styles for the image */
.Hire-first-header-logo {
  width: 100px;
  height: auto;
}

/* Media query for screens wider than 600px */
@media (min-width: 600px) {
  .Hire-first-header-logo {
    width: 150px;
  }
}

/* Media query for screens wider than 900px */
@media (min-width: 900px) {
  .Hire-first-header-logo {
    width: 200px;
  }
}

/* Media query for screens wider than 1200px */
@media (min-width: 1200px) {
  .Hire-first-header-logo {
    width: 250px;
  }
}



.New__applicant__row {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 1rem; */
}

.New__applicant__row p {
  /* margin: 0 0 8px 0; */
}

.hire-optional, .hire-impotant {
  font-size: 0.875em;
}

.AddJobinput {
  min-width: 100%;
  border: 1px solid #D2D6DA;
  font: inherit;
  letter-spacing: inherit;
  background: none;
  padding: 16.5px;
  border-radius: 12px;
}

/* Media queries for responsive design */

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
 .MuiStepper-root {
    display: flex;
    padding: 10px !important;
}

  .AddJobinput {
    padding: 14px;
    border-radius: 10px;
  }

  .hire-optional, .hire-impotant {
    font-size: 0.8em;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .New__applicant__row {
    /* padding: 8px; */
  }

  .AddJobinput {
    padding: 12px;
    border-radius: 8px;
  }

  .hire-optional, .hire-impotant {
    font-size: 0.75em;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 480px) {
  .New__applicant__row {
    /* padding: 5px; */
  }

  .AddJobinput {
    padding: 10px;
    border-radius: 6px;
  }

  .hire-optional, .hire-impotant {
    font-size: 0.7em;
  }
}


.New__applicant__row {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 1rem; */
}

.New__applicant__row > p {
  margin: 0 0 8px 0;
  font-size: 1rem; /* Base font size */
}

.hire-optional, .hire-impotant {
  font-size: 0.875em;
}

.AddJobinput {
  min-width: 100%;
  border: 1px solid #D2D6DA;
  font: inherit;
  letter-spacing: inherit;
  background: none;
  padding: 16.5px;
  border-radius: 12px;
}

/* Media queries for responsive design */

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .New__applicant__row {
    /* padding: 10px; */
  }

  .New__applicant__row > p {
    font-size: 0.95rem !important; /* Adjust font size for smaller screens */
  }

  .AddJobinput {
    padding: 14px;
    border-radius: 10px;
  }

  .hire-optional, .hire-impotant {
    font-size: 0.8em;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .New__applicant__row {
    /* padding: 8px; */
  }

  .New__applicant__row > p {
    font-size: 0.9rem !important; /* Further adjust font size for smaller screens */
  }

  .AddJobinput {
    padding: 12px;
    border-radius: 8px;
  }

  .hire-optional, .hire-impotant {
    font-size: 0.75em;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 480px) {
  .New__applicant__row {
    /* padding: 5px; */
  }

  .New__applicant__row > p {
    font-size: 0.65rem !important; /* Further adjust font size for the smallest screens */
  }
  .notice__period > p {
    font-size: 0.65rem !important; /* Further adjust font size for the smallest screens */
  }
  .New__applicant__column > p {
    font-size: 0.65rem !important; /* Further adjust font size for the smallest screens */
  }

  .AddJobinput {
    padding: 10px;
    border-radius: 6px;
  }

  .hire-optional, .hire-impotant {
    font-size: 0.7em;
  }
}



.btn-download{
  background-color: #5CB85C;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.current-page-img {
  background-color: #f8f8f8;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 5rem;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.1)
    ),
    url(../img/optimization.jpg);
  object-fit: cover;
   padding: 10rem 5rem;
    /* transform: rotate(180deg) !important; */
}

@media only screen and (max-width: 800px) {
  .current-page-img {
    margin-top: 4rem;
      padding: 6rem 5rem;
  }
}
@media only screen and (max-width: 500px) {
  .current-page-img {
    margin-top: 4rem;
      padding: 5rem 5rem;
  }

  .current-page-contain h1 {
    color: #fff;
    font-weight: bold;
    text-align: center !important;
    width: 100% !important;
  }
}
.current-page-contain {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 9.5rem 5rem;
}
.current-page-contain h1 {
  color: #fff;
  font-weight: bold;
}

#openings-colour {
  color: #f95738;
}

.current-header {
  color: #000;
  font-weight: bold;
  margin: auto;
  text-align: center;
}

.faq-header {
  font-size: 42px;
  border-bottom: 1px dotted #ccc;
  padding: 24px;
}

.faq-content {
  margin: 0 auto;
}

.faq-question {
  padding: 20px 0;
  border-bottom: 1px solid #f95738;
}

.panel-title {
  font-size: 1rem;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 10px 10px 0 48px;
  display: block;
  cursor: pointer;
}

.panel-content {
  font-size: 1rem;
  padding: 0px 14px;
  margin: 10px; 
  overflow: hidden;  
}

.panel-content p{
  margin-bottom: 20px;
}
.panel-content ul{
  margin-bottom: 20px;
}

.panel:checked ~ .panel-content {
  height: auto;
  opacity: 1;
  padding: 10px 0px;
}

.plus {
  position: absolute;
  margin-left: 10px;
  margin-top: 4px;
  z-index: 5;
  font-size: 42px;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.panel:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  color: #f95738;
}

.panel {
  display: none;
}

#panel-content-sub {
  padding-left: 3rem;
}

.panel-date {
  padding-left: 20px;
}

.modal-body {
  padding: 0px !important;
}
.job-image {
  max-width: 200px;
  max-height: 100px;
}

.ImWarning-error {
  position: relative;
  bottom: 2px;
  z-index: 99;
}

.FiCheckCircle-success {
  display: flex;
  justify-content: flex-start;
}

#panel-content-h5 h5 {
  color: #f95738;
  font-size: medium;
}

.faq-question-container {
  margin-bottom: 10em !important;
}

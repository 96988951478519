.modal-fullscreen .modal-body {
  background-color: whitesmoke !important;
}

#Textarea {
  height: 8rem;
}

#bg-image {
  margin-bottom: 50px;
}
#main-container {
  margin: 20px auto;
  width: 100%;
  max-width: 50rem;
  margin-top: 20px;
}

@media (max-width: 768px) {
  #main-container {
    /* margin: 60px 0px; */
    max-width: 100%;
  }
}
header {
  margin-top: 50px !important;
  margin: 20px;
  padding: 20px;
  background: white;
  border-radius: 15px;
  border: 1px solid #c5c3c6;
  box-shadow: 0 0 3px #46494c;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 600px) {
  header {
    margin: 7px;
    padding: 7px;
  }
}
#title {
  margin: 10px auto;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  color: #255aa0;
  border: 2px solid #255aa0;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 5px;
}

#description {
  margin: 10px auto;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #a9aaac;
}

@media (max-width: 600px) {
}
#form-container {
  margin: 20px;
  padding: 20px;
  background: white;
  border-radius: 15px;
  border: 1px solid #c5c3c6;
  box-shadow: 0 0 3px #46494c;
}

.question-container {
  margin: 20px;
  background: white;
}

@media (max-width: 600px) {
  .question-container {
    margin: 7px;
  }

  #form-container {
    margin: 7px;
    padding: 7px;
  }
}
.question-label {
  display: block;
  margin: 10px 0;
  padding: 5px 10px;
  height: 40px;
  width: 100%;
  padding-top: 0;
  line-height: 40px;
  font-weight: bold;
  color: #255aa0;
}

.row-input,
.big-size-input {
  display: block;
  margin: 10px 0;
  padding: 5px 10px;
  height: 40px;
  width: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #c5c3c6;
  transition: box-shadow 0.3s;
  box-shadow: none !important;
  outline: none !important;
}

.row-input:focus,
.big-size-input:focus {
  box-shadow: none !important;
  border: 2px solid #f95738;
}
.row-input:active,
.big-size-input:active {
  box-shadow: none !important;
}

.option-group {
  display: block;
  margin: 10px 0;
  padding: 5px 10px;
  height: auto;
  width: 100%;
  padding: 1px;
  background: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #c5c3c6;
}

.option-container {
  display: block;
  margin: 10px 0;
  padding: 5px 10px;
  height: 30px;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
}

.option-label {
  margin-left: 10px;
  color: #46494c;
}

input:checked + .option-label {
  font-weight: bold;
}

.big-size-input {
  height: 120px;
  resize: none;
}

#submit {
  margin: 20px;
  height: 40px;
  width: calc(100% - 2 * 20px);
  background: #255aa0;
  border-radius: 5px;
  border: 1px solid #c5c3c6;
  font-weight: 600;
  text-transform: capitalize;
  color: white;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

@media (max-width: 600px) {
  #submit {
    margin: 7px;
    width: calc(100% - 2 * 7px);
  }
}
#submit:hover {
  box-shadow: 0 0 6px #c5c3c6;
}

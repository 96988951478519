#iso-quality .iso-quality-intro {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.55)
    ),
    url("../img/iso.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 5rem;
}
.iso-quality-intro-container {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 1.5rem 5rem;
}
.iso-quality-intro-content {
  color: #fff;
  text-align: center;
}
.iso-quality-intro-content h1 {
  font-weight: bold;
}
.iso-quality-intro-content span,
.about-iso-content span,
.quality-policy-statement span {
  color: #f04217;
}
#iso-quality .quality-policy-statement h4 {
  font-size: 2rem;
}
#iso-quality .about-iso-img1 {
  background-image: url("../img/ISO_9001-20152.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 350px;
}
#iso-quality .about-iso {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5rem;
}
#iso-quality .about-iso-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#iso-quality .about-iso-content h4 {
  font-weight: bold;
  margin-bottom: 1.25rem;
}
#iso-quality .about-iso-content p {
  width: 80%;
}
#iso-quality .quality-policy-statement {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border-radius: 30px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 2px outset rgba(255, 255, 255, 0.3);
  box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.2);
  margin: 2rem;
  padding: 3rem;
  line-height: 3;
}

#iso-quality .quality-policy-statement h4 {
  text-align: center;
  font-weight: bold;
}
#iso-quality .date-and-signature {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#iso-quality .date-and-signature p {
  font-weight: bold;
  font-size: 18px;
}
.document-history-title {
  border: 1px solid;
  padding: 0 10px;
}
.document-title p {
  font-weight: 700;
  font-size: 16px;
}
.document-table table {
  width: 100%;
  margin: 20px 0;
}
th,
td {
  border: 1px solid black;
  padding: 0 10px;
  font-weight: 500;
}
.approval-subtest {
  color: #000 !important;
  font-size: 14px;
  font-weight: 500;
}
.document-outline{
  line-height: 3;
} 
.introduction h1{
  font-size: 22px;
  font-weight: 500;
  
}
.document-outline p{
  width: 70%;
}
.reason-highlight-heading{
  font-weight: bold;
}
.reasons-list{
  margin-left: 30px;
  list-style:inside ;
}
.reasons-list-two{
    margin-left: 30px;
  list-style: lower-alpha;
}
.reason-list-three{
     margin-left: 30px;
  list-style: disc;
}
.reason-list-four{
list-style: circle;
  margin-left: 30px;
}
.reason-list-five{
  list-style:upper-alpha;
  margin-left: 30px;
}
.ndpr-logo{
  margin: auto;
  width: 50%;
  height: 70%;
}
.ndpr-logo img{
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 480px) {
  #iso-quality .about-iso {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 4rem;
  }
  #iso-quality .about-iso-content p {
    width: 100%;
    text-align: center;
  }
  #iso-quality .about-iso-content h4 {
    text-align: center;
  }
  #iso-quality .quality-policy-statement {
    width: 100%;
    margin: 1rem 0;
    border-radius: 0;
    padding: 2rem;
    border: none;
    line-height: 2;
    background: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    box-shadow: none;
  }
  #iso-quality .quality-policy-statement h4 {
    font-size: 18px;
  }

  #iso-quality .quality-policy-statement p {
    width: 100%;
    text-align: justify;
    margin: 20px 0;
  }
  #iso-quality .quality-policy-statement ol {
    padding: 0;
  }
  #iso-quality .quality-policy-statement li {
    margin: 20px 0;
    text-align: center;
  }
  #iso-quality .date-and-signature {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #iso-quality .date-and-signature .signature p {
    text-align: center;
  }
  #iso-quality .date-and-signature .date {
    display: flex;
    flex-direction: column-reverse;
  }
}

#careers section {
  margin-top: 3rem;
  margin-bottom: 5rem;
}
#careers .careers-intro {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.55)
    ),
    url("../img/career.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 5rem;
}

.crrs-intr-cntnr {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 8rem 5rem;
}
.crrs-intr-cntnt {
  color: #fff;
  width: 55%;
}
.crrs-intr-cntnt h1 {
  font-weight: bold;
}
.crrs-intr-cntnt h1 span {
  color: #f04217;
}

.crrs-intr-cntnt button {
  margin-top: 2rem;
}

#careers h3 {
  font-weight: bold;
}
#careers h3 span {
  color: #f04217;
}

#careers .crrs-core {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#careers .crrs-core h3 {
  text-align: center;
}
#careers .crrs-three-core {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  width: 100%;
  margin-top: 2.5rem;
}
#careers .crrs-core-img1 {
  background-image: url("../img/Careers22.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 17rem;
}
#careers .crrs-core-img2 {
  background-image: url("../img/career-up.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 17rem;
}
#careers .crrs-core-img3 {
  background-image: url("../img/Careers23.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 17rem;
}
#careers .crrs-core-card h5 {
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
}

.crrs-core-card{
 transition: 0.15s !important; 
}
.crrs-core-card:hover{
transform: scale(1.09) !important;
}

.othr-crr-aspcts {
  display: grid;
  gap: 2rem;
}
.othr-crr-aspcts.seg1 {
  grid-template-columns: 0.75fr 1fr;
}
.othr-crr-aspcts.seg2 {
  grid-template-columns: 1fr 0.75fr;
}
.othr-crr-aspcts.seg3 {
  grid-template-columns: 0.75fr 1fr;
}
.othr-crr-aspcts.seg4 {
  grid-template-columns: 1fr 0.75fr;
}
.other-crr-aspcts-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 0;
}
.other-crr-aspcts-info h5 {
  font-weight: bold;
}
.other-crr-aspcts-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.other-crr-aspcts-img.cs1 {
  background-image: url("../img/Careers12.jpeg");
}
.other-crr-aspcts-img.cs2 {
  background-image: url("../img/career-middle1.jpeg");
}
.other-crr-aspcts-img.cs3 {
  background-image: url("../img/career-middle2.jpeg");
}
.other-crr-aspcts-img.cs4 {
  background-image: url("../img/career-down.jpeg");
}

/* --- Media Query --- */
/* Mobile Screen */
@media only screen and (max-width: 480px) {
  #careers .careers-intro {
    margin-top: 4rem;
  }
  .crrs-intr-cntnr {
    padding: 2rem;
  }
  .crrs-intr-cntnt {
    width: 100%;
  }
  .othr-crr-aspcts {
    display: grid;
    grid-template-columns: 1fr !important;
    grid-template-rows: 0.85fr 1fr !important;
    gap: 2rem;
  }
  .other-crr-aspcts-img {
    grid-row: 1;
  }
  .other-crr-aspcts-info {
    padding: 0;
  }
}

/* Mobile Dimension & Tablet */
@media only screen and (max-width: 768px) {
  #careers .crrs-three-core {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}

.other-crr-p {
  height: 10rem;
}


/* accordion-button */

.accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: #fff !important;
				box-shadow: none !important;
    /* // box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #dc3545; */
}

.accordion-item:first-of-type .accordion-button {
				box-shadow: none !important;
				color: #000 !important;
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-button {
			box-shadow: none !important;
				font-weight: bold !important;
}



.accordion {
     --bs-accordion-color: var(--bs-body-color);  
     --bs-accordion-bg: var(--bs-body-bg);  
     --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;  
     --bs-accordion-border-color: none !important;  
     /* // --bs-accordion-border-color: var(--bs-border-color);   */
    --bs-accordion-border-width: var(--bs-border-width);
    --bs-accordion-border-radius: var(--bs-border-radius);
    --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: var(--bs-accordion-bg); 
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    
    --bs-accordion-btn-focus-border-color: #86b7fe;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: var(--bs-primary-text-emphasis);
    --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-body {
    /* padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); */
				padding: 0px 1rem !important;
}

#accordion-body-form{
border: 1px solid #D9D9D9 !important;  
padding: 1rem;
margin: 1rem;
border-radius: 10px;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600&display=swap');
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", "Source Sans Pro", sans-serif !important;
}

body {
  background-color: #fff;
    height: -webkit-fill-available !important;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

a {
  color: #000;
  text-decoration: none;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 2px;
background-color: #fff ;
}

button.ccsnl-btn {
  background-color: #f04217 !important;
  color: #fff;
  font-weight: bold;
  padding: 0.25rem 1.5rem;
  border: 2px solid #f04217;
  transition: 0.25s;
}
button.ccsnl-btn:hover {
  background-color: transparent !important;
  color: #f04217 !important;
}

main {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 2.5rem 5rem;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}


:root {
  --clr-white: #fff; ;
  --txt-black: #303030;
  --clr-blue: #005b90;
  --clr-dark-blue:#124383;
  --clr-blue-semi: #21D4FD;
  --clr-orange: #f53939;
  --clr-gray: rgba(128, 128, 128, 0.455);
  --clr-linear-gradient: linear-gradient(135deg, #21D4FD 0%, #124383 100%);
  --transition: all 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
  --box-shadow: 0 0 4px rgba(0, 0, 0, 0.025), 0 0 16px rgba(0, 0, 0, 0.025),
    0 0 32px rgba(0, 0, 0, 0.025), 0 0 64px rgba(0, 0, 0, 0.025); 
    --back-3: rgba(0,0,0,0.251);
--black-2: rgba(0,0,0,0.902);
--black: rgba(0,0,0,1);
-venice-blue-2: rgba(0,91,144, 0.582);
--venice-blue: rgba(0,91,144,1);
--lade: rgba(1,176,50,1);
--allports:rgba(2,117,117,1);
--science-b1ue: rgba(10,182,194,1);
--midnight-bule: rgba (18, 67, 131, 1);
--licorice: rgba (20, 28, 20,1); 
--forest-green: rgba (23,173.55,1);
--eerie-black-2: rgba (24,24,24,0.2);
  --primary-color: #185ee0;
  --secondary-color: #e6eef9;
   --border:#cdd1d3;
}

 
.notice {
    margin-bottom: 100px;
}
.article-massage{ 
     display: flex;
    justify-content: center; 
    
}

#Visibility{
  margin-top: 10px;
}
.article { 
    width: 30vw;
    margin: 1vh auto 2vh;
    border-radius: 15px; 
    z-index: 3147483000 ;
    font-size: 1.4rem;
    cursor: pointer; 
     height: 60vh;
     bottom : 20% !important;
    position: fixed;
}
 

@media only screen and (max-width:  480px) {
  .article {
    width: 100% !important; 
    bottom : -18px !important;
    height: 40vh !important;
  }

}
@media  screen and (max-width: 768px) {
  .article {
    width: 100% !important; 
    bottom : -15px !important;
    height: 50vh !important;
  }
.review-carousel .carousel-caption p{
    font-size: 1rem !important;
    margin: 0 0.1rem !important;
    
  }

  .review-carousel {
  margin-top: 2rem;
  height: 20rem !important;
}
  
}
 
.rfq-modal .modal-header .btn-close:focus {
  box-shadow: 0 0 3px #f04217;
  border: 1px solid #f04217;
}
.rfq-modal form {
  padding: 1rem;
}
.rfq-modal input,
.rfq-modal select,
.rfq-modal textarea {
  outline: none;
  border: 0;
  width: 100%;
  display: block;
  padding: 0.65rem 1rem;
  transition: 0.25s;
  margin-bottom: 2rem;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
}
.rfq-modal input:focus,
.rfq-modal select:focus,
.rfq-modal textarea:focus {
  outline: none;
  border: 0;
  border: 1px solid #f04217;
}
.rfq-modal-btn {
  display: flex;
  justify-content: flex-end;
}

.slick-arrow {
  display: none !important;
}



 

.slick-slide img {
  vertical-align: middle !important;
  border: none !important;
}

.success-border {
  border: 2px solid green;
}

.error-border {
  border: 2px solid red;
}

.alert-dismissible .btn-close {
  box-shadow: none !important;
}

 
/* --- Testimonial Carousel --- */
.testimonials h3 {
  margin: 0 !important;
}
.review-carousel {
  margin-top: 2rem;
  height: 15rem;
}
.review-carousel .img-area {
  width: 150px;
  margin: auto;
  border: 2px solid #fff;
  overflow: hidden;
}
.review-carousel .img-area img {
  width: 100%;
}
.review-carousel .carousel-caption {
  position: static;
  padding-bottom: 15px;
  padding-top: 0;
  color: #000;
}
.review-carousel .carousel-caption p {
  font-size: 1.5rem;
  margin: 0 4rem  ;
  font-family: 'Courgette', cursive !important;
}
.review-carousel .carousel-caption h3 {
  margin: 0 !important;
  font-weight: bold;
}
.review-carousel .carousel-indicators {
  bottom: -35px;
}
.review-carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.swal-button {
    background-color: #f04217 !important;

}

.data-policy{
  font-size:medium !important;
  color: #f04217 !important;
}
.data-policy:hover{
  font-size:medium !important;
  color: #f0421757 !important;
}


 
@media only screen and (max-width: 480px) {
  main {
    padding: 2.5rem 2rem;
  }
  .rfq-modal form {
    padding: 0.5rem;
  }
}
/* Extra Dimension */
@media only screen and (max-width: 1188px) {
  main {
    padding: 2.5rem 2rem;
  }
}

/*** --- Other CSS Files --- ***/
@import url(./Navbar.css);
@import url(./Footer.css);
@import url(./CaseStudies.css);
@import url(./CaseStudiesSubPages.css);
@import url(./RequestForQuote.css);
@import url(./BeWorkFromHomeReady.css);
@import url(./Faqs.css);
@import url(./OutcessClients.css);
@import url(./Home.css);
@import url(./JobApplication.css);
@import url(./About.css);
@import url(./BusinessPartners.css);
@import url(./Ideology.css);
@import url(./Careers.css);
@import url(./CurrentOpenings.css);
@import url(./Gallary.css);
@import url(./Careers.css);
@import url(./CloudSolution.css);
@import url(./Omni-ChannelMarket.css);
@import url(./BusinessProdSolution.css);
@import url(./BusinessOutsource.css);
@import url(./CustomerEngmntSolution.css);
@import url(./LoaderPlus.css);
@import url(./NotFound.css);
@import url(./IsoQuality.css);
@import url(./Hire.css);
@import url(./ViewJob.css);
@import url(./Blogs.css);
@import url(./CaseStudyTitlePages.css);
@import url(../../pages/ExpiredPage/ExpiredPage.css);

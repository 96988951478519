#cloud-solutions .cld-intro {
  margin-top: 5rem;
}
#cloud-solutions .cld-intro-img {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.55),
      rgba(0, 0, 0, 0.15)
    ),
    url("../img/cloud-computing-GIF.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 100%; */
  max-width: 100%;
  /* height: auto; */
  height: 25rem;
}
#cloud-solutions .cld-intro-cntnr {
  margin: 0 auto;
  max-width: 100rem;
  padding: 0 5rem;
  margin-top: -2.5rem;
}
#cloud-solutions .cld-intro-details {
  background-color: #fff;
  width: 100%;
  padding: 2rem 3rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.025), 0 10px 15px rgba(0, 0, 0, 0.025);
}
#cloud-solutions .cld-intro-details button {
  margin-top: 1rem;
}
#cloud-solutions button.cld-rfq-lg {
  display: block;
}
#cloud-solutions button.cld-rfq-sm {
  display: none;
}

#cloud-solutions h1 {
  font-weight: bold;
}
#cloud-solutions h1 span,
#cloud-solutions h5 span {
  color: #f04217;
}

#cloud-solutions main {
  margin-top: 3rem;
}

#cloud-solutions .cld-sltns-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  margin-top: 5rem;
}


  .cld-card-move {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 5px 25px rgba(0, 0, 0, 0.05);
transition: 0.15s !important;
}
  .cld-card-move:hover{ 
 transform: scale(1.05) !important;
}

#cloud-solutions .cld-card-img1 {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0.65)
    ),
    url("../img/cloud.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #fff;
  text-align: center;
}
#cloud-solutions .cld-card-img1:hover {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.10),
      rgba(0, 0, 0, 0.10)
    ),
    url("../img/cloud.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #f04217;
  text-align: center;
}
#cloud-solutions .cld-card-img2 {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0.65)
    ),
    url("../img/telcoms.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #fff;
  text-align: center;
}
#cloud-solutions .cld-card-img2:hover {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.10),
      rgba(0, 0, 0, 0.10)
    ),
    url("../img/telcoms.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #f04217;
  text-align: center;
}
#cloud-solutions .cld-card-img3 {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0.65)
    ),
    url("../img/optimization.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #fff;
  text-align: center;
}
#cloud-solutions .cld-card-img3:hover {
  background: none,
    url("../img/optimization.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #f04217;
  text-align: center;
}
#cloud-solutions .cld-card-img4 {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0.65)
    ),
    url("../img/cloud-cm1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #fff;
  text-align: center;
}
#cloud-solutions .cld-card-img4:hover {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.10),
      rgba(0, 0, 0, 0.10)
    ),
    url("../img/cloud-cm1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #f04217;
  text-align: center;
}
#cloud-solutions .cld-card-img5 {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0.65)
    ),
    url("../img/Cloud-Storage.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #fff;
  text-align: center;
}
#cloud-solutions .cld-card-img5:hover {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.10),
      rgba(0, 0, 0, 0.10)
    ),
    url("../img/Cloud-Storage.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #f04217;
  text-align: center;
}
#cloud-solutions .cld-card-img6 {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0.65)
    ),
    url("../img/cloud-cm2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #fff;
  text-align: center;
}
#cloud-solutions .cld-card-img6:hover {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.10),
      rgba(0, 0, 0, 0.10)
    ),
    url("../img/cloud-cm2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #f04217;
  text-align: center;
}
#cloud-solutions .cld-card-img1 h5 {
  padding: 5.5rem 1rem;
}
#cloud-solutions .cld-card-img2 h5 {
  padding: 5.5rem 1rem;
}
#cloud-solutions .cld-card-img3 h5 {
  padding: 5.5rem 1rem;
}
#cloud-solutions .cld-card-img4 h5 {
  padding: 5.5rem 1rem;
}
#cloud-solutions .cld-card-img5 h5 {
  padding: 5.5rem 1rem;
}
#cloud-solutions .cld-card-img6 h5 {
  padding: 5.5rem 1rem;
}
#cloud-solutions .cld-card-details {
  text-align: center;
  padding: 2rem;
}

#cloud-solutions .cld-sltn-end {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  margin: 5rem 0;
}
.cld-card .cld-card-details p {
  font-size: 17px;
  text-align: left;
}
.cld-end-details {
  font-size: 18px;
}

#cloud-solutions .cld-end-details {
  padding: 2rem 0;
}
#cloud-solutions .cld-end-details p:nth-child(2) {
  font-weight: bold;
  color: #f04217;
  margin-top: 1.25rem;
}
#cloud-solutions .cld-end-details button {
  margin-top: 1.5rem;
}
#cloud-solutions .cld-end-img {
  background-image: url("../img/cloud3.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  image-rendering: auto;
  max-width: 100%;
  width: 100%;
  height: 400px;
}

/* Mobile Dimension & Tablet */
@media only screen and (max-width: 768px) {
  #cloud-solutions .cld-intro-details {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
  }
  #cloud-solutions .cld-intro {
    margin-top: 4rem !important;  
  }
  #cloud-solutions .cld-intro-details {
    padding: 2rem;
  }
  #cloud-solutions button.cld-rfq-sm {
    display: block;
    margin-top: 1.5rem;
  }
  #cloud-solutions button.cld-rfq-lg {
    display: none;
  }
  #cloud-solutions .cld-sltns-cards {
    display: grid;
    grid-template-columns: 1fr !important;
    row-gap: 3rem;
    margin-top: 2rem;
  }
  #cloud-solutions .cld-sltn-end {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.85fr 1fr;
    row-gap: 1rem;
  }
  #cloud-solutions .cld-end-details {
    padding: 0.05rem 0;
  }
  #cloud-solutions .cld-end-img {
    grid-row: 1;
  }

  
  
}

/* Small Dimension */
@media only screen and (max-width: 450px) {
  #cloud-solutions .cld-end-img {
    background-image: url("../img/cloud3.gif"); 
    height: 200px !important;
  }
}
/* Small Dimension */
@media only screen and (max-width: 600px) {
  #cloud-solutions .cld-end-img {
    background-image: url("../img/cloud3.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    image-rendering: auto;
    max-width: 100%;
    width: 100%;
    height: 300px;
  }
}
/* Extra Dimension */
@media only screen and (max-width: 1188px) {
  #cloud-solutions .cld-intro-cntnr {
    padding: 0 2rem;
  }
  #cloud-solutions .cld-sltns-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin-top: 3rem;
  }
  
}

.Crtical-colour {
  color: #f04217;
}

.cld-card-list {
  margin-left: 25px;
 text-align: left !important;
 
}

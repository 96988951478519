.ocm-intro {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.75)),
    url("../img/omi.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 5rem;
}
.ocm-intro-sm {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.75)),
    url("../img/problem.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding-bottom: 5rem;
}
.ocm-intr-containr {
  margin: 0 auto;
  max-width: 100rem;
  padding: 3.5rem 5rem;
}
.ocm-intr-contnt {
  color: #fff;
  width: 55%;
}
#omni-channel-marketing h1,
#omni-channel-marketing h3 {
  font-weight: bold;
}
#omni-channel-marketing h1 span,
#omni-channel-marketing h3 span {
  color: #f04217;
}
.ocm-intr-contnt button {
  margin-top: 1rem;
}

#omni-channel-marketing main {
  margin-top: -7rem;
}

#omni-channel-marketing main section:nth-child(1) {
  background-color: #fff;
  color: #000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 5px 25px rgba(0, 0, 0, 0.05);
  padding: 2rem 3rem;
}

.ocm-smartmsg-details {
  margin-top: 1.5rem;
}
.ocm-smartmsg-details h5 {
  font-weight: bold;
  color: #f04217;
}
.ocm-smartmsg-details p {
  width: 80%;
}

#omni-channel-marketing main section:nth-child(2) {
  margin: 4rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
}
#omni-channel-marketing .ocm-othrs-card {
  background-color: #fff;
  color: #000;
  display: grid;
  grid-template-rows: 1fr 1fr;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 5px 25px rgba(0, 0, 0, 0.05);
}

.ocm-othrs-card {
  height: 40rem !important;
}
.ocm-othrs-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

#omni-channel-marketing .ocm-othrs-info {
  padding: 2rem;
}
#omni-channel-marketing .ocm-othrs-info h4 {
  color: #f04217;
}

/* --- Media Query --- */
/* Mobile Screen */
@media only screen and (max-width: 480px) {
  .ocm-intro {
    margin-top: 4rem;
  }
  .ocm-intr-contnt {
    width: 100% !important;
  }
  #omni-channel-marketing main section:nth-child(1) {
    padding: 2rem 1.5rem;
  }
  .ocm-smartmsg-details p {
    width: 100%;
  }
  #omni-channel-marketing .ocm-othrs-card {
    display: grid;
    grid-template-rows: 0.75fr 1fr;
  }
}

/* Mobile & Tablet Dimension */
@media only screen and (max-width: 768px) {
  #omni-channel-marketing main section:nth-child(2) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }
}

/* Extra Dimension */
@media only screen and (max-width: 1188px) {
  .ocm-intr-containr {
    padding: 3rem 2rem;
  }
  .ocm-intr-contnt {
    width: 80%;
  }
}

.ocm-intr-contnt {
  overflow-x: hidden !important;
}
.ocm-smartmsg-details {
  overflow-x: hidden !important;
}

.ocm-intr-containr {
  overflow-x: hidden !important;
}

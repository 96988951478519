#business-productivity-solutions .bps-intro {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.55)
    ),
    url("../img/talk.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 5rem;
}
.bps-intr-containr {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 8.5rem 5rem;
}
.bps-intr-contnt {
  color: #fff;
  width: 55%;
}
.bps-intr-contnt button,
.biz-sol-dsgn-info button {
  margin-top: 1rem;
}
#business-productivity-solutions h1,
.biz-sol-cards-title h3 {
  font-weight: bold;
}
#business-productivity-solutions h1 span,
.biz-sol-cards-title h3 span {
  color: #f04217;
}

#business-productivity-solutions section {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

#business-productivity-solutions .biz-sol-dsgn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
}
#business-productivity-solutions .biz-sol-dsgn-info {
  padding: 4rem 0;
}
#business-productivity-solutions .biz-sol-dsgn-img {
  background-image: url("../img/people.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

#business-productivity-solutions .wrk-frc-optmztn {
  background-color: #120605;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
#business-productivity-solutions .wrk-frc-optmztn-img {
  background-image: url("../img/colab.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.wrk-frc-optmztn-info {
  padding: 2rem 3rem;
  max-width: 50rem;
}
.wrk-frc-optmztn-info h3 {
  font-weight: bold;
}
.wrk-frc-optmztn-info ul {
  padding: 1rem 2.5rem;
  list-style: circle;
}
.wrk-frc-optmztn-info li {
  padding: 0 0.85rem;
  margin-bottom: 1.5rem;
}

.biz-sol-cards-title {
  text-align: center;
  margin-bottom: 2rem;
}
.biz-sol-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2.5rem;
}
.biz-sol-card {
  padding: 2rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05), 0 0 50px rgba(0, 0, 0, 0.05);
  transition: 0.15s !important;
}
.biz-sol-card:hover {
 transform: scale(1.05) !important;
}
.biz-sol-card span {
  color: #f04217;
}
.biz-sol-card p {
  margin-top: 1.5rem;
}

.biz-apps-table-title {
  text-align: center;
}
.biz-apps-table-title h3 {
  font-weight: bold;
}
.biz-apps-table-title h3 span {
  color: #f04217;
}
.biz-apps-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 4rem;
}
.biz-apps-tbl-cntnt {
  padding: 3rem 4rem;
}
.biz-apps-tbl-cntnt:nth-child(odd) {
  border-right: 3px dotted #000;
}
.biz-apps-tbl-cntnt:nth-child(1),
.biz-apps-tbl-cntnt:nth-child(2) {
  border-bottom: 3px dotted #000;
}
.biz-apps-tbl-cntnt h4 {
  font-weight: bold;
  color: #f04217;
}

.biz-sol-office365 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
}
.biz-office365-img {
  background-image: url("../img/microsoft.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 22rem;
}
.biz-office365-info {
  padding: 2rem 0;
}
.biz-office365-info h3 {
  font-weight: bold;
  color: #f04217;
}

/* --- Media Query --- */
/* Mobile Screen */
@media only screen and (max-width: 480px) {
  #business-productivity-solutions .bps-intro {
    margin-top: 4rem;
  }
  #business-productivity-solutions .biz-sol-dsgn {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1.5rem;
  }
  #business-productivity-solutions .biz-sol-dsgn-info {
    padding: 0;
    grid-row: 2;
  }
  .wrk-frc-optmztn-info {
    padding: 2rem;
  }
  .biz-sol-office365 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.85fr 1fr;
    gap: 1rem;
  }
  .biz-apps-table {
    display: grid;
    grid-template-columns: 1fr !important;
    margin-top: 4rem;
  }
  .biz-apps-tbl-cntnt {
    padding: 3rem 0 !important;
    border: 0 !important;
  }
}

/* Extra Dimension */
@media only screen and (max-width: 768px) {
  .bps-intr-contnt {
    width: 100%;
  }
  #business-productivity-solutions .wrk-frc-optmztn {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .biz-sol-cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }
  .biz-apps-tbl-cntnt {
    padding: 3rem;
  }
}

/* Extra Dimension */
@media only screen and (max-width: 1188px) {
  .bps-intr-containr {
    padding: 2rem;
  }
}

.biz-sol-dsgn-info {
  overflow-x: hidden !important;
}
.biz-sol-dsgn-img {
  overflow-x: hidden !important;
}
.biz-sol-cards-title {
  overflow-x: hidden !important;
}

.wrk-frc-optmztn {
  overflow-x: hidden !important;
}
.biz-apps-table-title {
  overflow-x: hidden !important;
}

.biz-sol-office365 {
  overflow-x: hidden !important;
}
.biz-apps-tbl-cntnt {
  overflow-x: hidden !important;
}

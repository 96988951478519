.card-quote-main {
  margin-top: 100px !important;
  margin-bottom: 0px !important;
  border-radius: 0px !important;
  width: 100% !important;
}

.card-quote-container {
  display: flex !important;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 80px !important;
  flex-wrap: wrap;
}

.card-quote {
  border-radius: 10px !important; 
  border: 1px solid #f04217;
  margin-bottom: 50px !important;
}

.card-quote:hover {
  transform: translateY(-20px); 
}
.request-img-quote {
  border-radius: 10px 10px 0px 0px !important;
  background-color: #fff !important;
  border-bottom: 1px solid #f04217 !important;
  object-fit: cover !important;

  padding-right: 5px !important;
  padding-left: 5px !important;
}

#Form-quote {
  background-color: whitesmoke !important;
  width: 500px !important;
  border-radius: 10px !important; 
  margin-bottom: 100px !important;
  margin-left: 200px; 
  margin-top: 50px;
  outline: none !important;
  box-shadow: none !important;
}

#Form-quote:focus {
  box-shadow: none !important;
  border: 2px solid #f04217 !important;
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 800px) {
  #Form-quote {
    display: flex !important;
    justify-content: space-around !important;
    align-items: center;
    margin-left: 0px !important;
    margin: auto !important;
    width: 100% !important;
    margin-bottom: 40px !important;
  }

  #Form-quote:focus {
    box-shadow: none !important;
    border: 2px solid #f04217;
  }
  .card-quote-container {
    display: flex !important;
    justify-content: space-evenly;
    margin-top: auto !important;
    width: 100%;
  }

  .card-quote {
    margin: 30px !important;
  }

  .arrow-down h6 {
    font-size: medium !important;
    margin-top: 20px !important ;
  }
}

.arrowrightAlt {
  margin-bottom: 50px !important;
  margin-top: 50px !important ;
}

.arrow-down h6 {
  margin-top: 50px !important ;
  margin-bottom: 50px !important;
}

#btn-quote-container {
  display: flex !important;
  justify-content: flex-end !important;
}

.arrowright {
  display: flex;
  align-items: center;
}
.arrowrightAlt {
  color: #f04217;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.arrow-down h6 {
  font-size: x-large;
}

img {
  max-width: 100%;
  height: auto;
}

.sec-title-style1 {
  position: relative;
  display: block;
  margin-top: -9px;
  padding-bottom: 50px;
}
.sec-title-style1.max-width {
  position: relative;
  display: block;
  max-width: 770px;
  margin: -9px auto 0;
  padding-bottom: 52px;
}
.sec-title-style1.pabottom50 {
  padding-bottom: 42px;
}
.sec-title-style1 .title {
  position: relative;
  display: block;
  color: #131313;
  font-size: 3rem;
  line-height: 46px;
  font-weight: 700;
  text-transform: uppercase;
}
.sec-title-style1 .title.clr-white {
  color: #ffffff;
}
.sec-title-style1 .decor {
  position: relative;
  display: block;
  width: 70px;
  height: 5px;
  margin: 19px 0 0;
}
.sec-title-style1 .decor:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 5px;
  height: 5px;
  background: #f04217;
  border-radius: 50%;
  content: "";
}
.sec-title-style1 .decor:after {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 5px;
  height: 5px;
  background: #f04217;
  border-radius: 50%;
  content: "";
}
.sec-title-style1 .decor span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  background: #f04217;
  margin: 2px 0;
}

.sec-title-style1 .text {
  position: relative;
  display: block;
  margin: 7px 0 0;
}
.sec-title-style1 .text p {
  position: relative;
  display: inline-block;
  padding: 0 15px;
  color: #131313;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}
.sec-title-style1 .text.clr-yellow p {
  color: #f04217;
}
.sec-title-style1 .text .decor-left {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 70px;
  height: 5px;
  background: transparent;
}
.sec-title-style1 .text .decor-left span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  background: #f04217;
  content: "";
  margin: 2px 0;
}
.sec-title-style1 .text .decor-left:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 5px;
  height: 5px;
  background: #f04217;
  border-radius: 50%;
  content: "";
}
.sec-title-style1 .text .decor-left:after {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 5px;
  height: 5px;
  background: #f04217;
  border-radius: 50%;
  content: "";
}

.sec-title-style1 .text .decor-right {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 70px;
  height: 5px;
  background: transparent;
}
.sec-title-style1 .text .decor-right span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  background: #f04217;
  content: "";
  margin: 2px 0;
}
.sec-title-style1 .text .decor-right:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
  height: 5px;
  background: #f04217;
  border-radius: 50%;
  content: "";
}
.sec-title-style1 .text .decor-right:after {
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  width: 5px;
  height: 5px;
  background: #f04217;
  border-radius: 50%;
  content: "";
}

.sec-title-style1 .bottom-text {
  position: relative;
  display: block;
  padding-top: 16px;
}
.sec-title-style1 .bottom-text p {
  color: #848484;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin: 0;
}
.sec-title-style1 .bottom-text.clr-gray p {
  color: #cdcdcd;
}
.contact-address-area {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 100px 0 120px;
}
.contact-address-area .sec-title-style1.max-width {
  padding-bottom: 72px;
}
.contact-address-box {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.single-contact-address-box {
  position: relative;
  display: block;
  background: #131313;
  padding: 85px 30px 77px;
}

.single-contact-address-box h3 {
  color: #fff;
  margin: 0px 0 9px;
}
.single-contact-address-box h2 {
  color: #f04217;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 19px;
  text-align: center;
}
.single-contact-address-box a {
  color: #fff;
}

.single-contact-address-box.main-branch {
  background: #f04217;
  padding: 53px 30px 51px;
  margin-top: -20px;
  margin-bottom: -20px;
}
.single-contact-address-box.main-branch h3 {
  color: #131313;
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 38px;
  text-transform: uppercase;
  text-align: center;
}
.single-contact-address-box.main-branch .inner {
  position: relative;
  display: block;
}
.single-contact-address-box.main-branch .inner ul {
  position: relative;
  display: block;
  overflow: hidden;
}
.single-contact-address-box.main-branch .inner ul li {
  position: relative;
  display: block;
  padding-left: 110px;
  border-bottom: 1px solid #737373;
  padding-bottom: 23px;
  margin-bottom: 24px;
}
.single-contact-address-box.main-branch .inner ul li:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.single-contact-address-box.main-branch .inner ul li .text {
  position: relative;
  display: block;
}
.single-contact-address-box.main-branch .inner ul li .text p {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 0;
}

.contact-info-area {
  position: relative;
  display: block;
  background: #ffffff;
  margin-bottom: 80px; 
}

@media only screen and (max-width: 600px) {
  .contact-form .text-box {
  position: relative;
  display: block;
  margin-top: 19px; 
  padding-bottom: 1em;
  text-align: center;
}
  .contact-info-area {
    width: 100% !important;
  }
  .input-box {
    width: 100% !important;
  }

  .float-left {
    display: none !important;
  }
  .contact-form {
    padding: 20px 10px 20px !important;
  }
  .sec-title-style1 .title {
    position: relative;
    display: block;
    color: #131313;
    font-size: 1.8rem;
    line-height: 46px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.contact-form {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 100px 60px 80px;
  box-shadow: 0 0 10px rgb(2 2 2 / 5%), 0 0 25px rgb(2 2 2 / 5%);
  z-index: 3;
}
.contact-form .sec-title-style1 {
  position: relative;
  display: block;
  padding-bottom: 51px;
  width: 80%;
}
.contact-form .text-box {
  position: relative;
  display: block;
  margin-top: 19px; 
  padding-bottom: 1em; 
}
.contact-form .text p {
  color: #848484;
  line-height: 26px;
  margin: 0;
}

.contact-form .inner-box {
  position: relative;
  display: block;
  background: #fff;
}
.contact-form form {
  position: relative;
  display: block;
}
.contact-form form .input-box {
  position: relative;
  display: block;
}

.contact-form form input[type="text"],
.contact-form form input[type="email"],
.contact-form form select,
.contact-form form textarea {
  position: relative;
  display: block;
  background: #ffffff;
  border: 1px solid #eeeeee;
  width: 100%;
  height: 55px;
  font-size: 16px;
  color: #000 !important;
  padding-left: 19px;
  padding-right: 15px;
  border-radius: 0px;
  margin-bottom: 20px;
  box-shadow: none !important;
  -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    outline: none !important;
}

.contact-form form textarea {
  height: 130px;
  padding-left: 19px;
  padding-right: 15px;
  padding-top: 14px;
  padding-bottom: 15px;
  box-shadow: none !important;
}
.contact-form form input[type="text"]:focus {
  /* border-color: #d4d4d4; */
  box-shadow: none !important;
  border: 2px solid #f04217 !important;
}
.contact-form form input[type="email"]:focus { 
  box-shadow: none !important;
  border: 2px solid #f04217 !important;
}

  .input-box-textarea:focus { 
  border: 2px solid #f04217  ;
 
}
.contact-form form textarea:focus { 
  border: 2px solid #f04217 !important;
  box-shadow: none !important;
}

.contact-form form button {
  position: relative;
  display: block;
  width: 100%;
  background: #f04217;
  border: 1px solid #f04217;
  color: #fff;
  font-size: 16px;
  line-height: 55px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  box-shadow: none !important;
}

.contact-form form button:hover {
  color: #f04217;
  background: #fff;
}

.BsClock {
  font-size: 60px;
  color: #fff;
}

.BsTelephone {
  font-size: 60px;
  color: #fff;
}

.FiMapPin h1 {
  font-size: 60px;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.FiMapPin p {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
.inner {
  height: 250px;
}

.FiMapPin {
  padding: 60px 0px;
}
 
#customer-engmnt-solution {
}

#customer-engmnt-solution .ces-intro {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.55)
    ),
    url("../img/customer1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 5rem;
}
.ces-intr-containr {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 4rem 5rem;
}
.ces-intr-contnt {
  color: #fff;
  width: 55%;
}
.ces-intr-contnt h4 {
  font-weight: bold;
}
.ces-intr-contnt h4 span {
  color: #f04217;
}
.ces-intr-contnt button {
  margin-top: 1rem;
}

.ces-main-brief {
  text-align: center;
  padding: 0.5rem 1rem;
  border-left: 5px solid #f04217;
  border-right: 5px solid #f04217;
  margin-bottom: 5rem;
}

#customer-engmnt-solution section {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.ces-biz-prcss {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
}
.ces-biz-prcss-info {
  padding: 4rem 0;
}
.ces-biz-prcss-info h5 {
  font-weight: bold;
  color: #f04217;
}
.ces-biz-prcss-img {
  background-image: url("../img/multicultural.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 15px;
  height: 350px;
 
}

.ces-others-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
}
.ces-othrs-card {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
}
.ces-othrs-card-img {
  background-image: url("../img/customer3.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.ces-othrs-card-img2 {
  background-image: url("../img/customer4.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.ces-othrs-card-info h5 {
  font-weight: bold;
  color: #f04217;
  margin-top: 1rem;
}
.ces-othrs-card-info button {
  margin-top: 1rem;
}

/* --- Media Query --- */
/* Mobile Screen */
@media only screen and (max-width: 480px) {
  #customer-engmnt-solution .ces-intro {
    margin-top: 4rem;
  }
  .ces-biz-prcss,
  .ces-others-cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2.5rem;
  }
  .ces-biz-prcss {
    gap: 1rem;
  }
  .ces-biz-prcss-info {
    padding: 0;
  }
  .ces-biz-prcss-img {
    grid-row: 1;
  }
}

/* Extra Dimension */
@media only screen and (max-width: 768px) {
  .ces-intr-contnt {
    width: 100%;
  }
  
}

/* Extra Dimension */
@media only screen and (max-width: 1188px) {
  .ces-intr-containr {
    padding: 2rem;
  }
}


/* --- customer Cards --- */
  .customer-cards-three  {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
}
 .customer-card {
  background-color: #fff;
  padding: 3rem 2rem;
  text-align: center;
  user-select: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05), 0 0 50px rgba(0, 0, 0, 0.05);
  transition: 0.15s !important;
}
  .customer-card:hover {
  transform: scale(1.05) !important;
}
  .customer-card span {
  color: #838383;
  font-weight: bold;
}
  .customer-card h4 {
  color: #f04217;
  font-weight: bold;
  margin-top: 1.5rem;
}


@media screen and (max-width: 600px) {
   .customer-cards-three {
  display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1.5rem;
}
  }
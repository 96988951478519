/* --- */
/*** --- LANDING CAROUSEL --- ***/
#home button {
  background-color: #f04217;
  color: #fff;
  font-weight: bold;
  padding: 0.25rem 1.5rem;
  border: 2px solid #f04217;
  transition: 0.25s;
}

#home button:hover {
  background-color: transparent;
  color: #f04217;
}
#landing-carousel {
  position: relative;
  margin-top: 5rem;
}
#landing-carousel .carousel {
  z-index: 0;
}
#landing-carousel .carousel-indicators,
#landing-carousel .carousel-control-prev,
#landing-carousel .carousel-control-next {
  display: none;
}
#landing-carousel .carousel-item {
  position: relative;
  height: 75vh;
}
#landing-carousel .carousel-item img {
  height: 100%;
  object-fit: cover;
  animation: imgScale 30s linear infinite;
}
@keyframes imgScale {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}
#landing-carousel .carousel-caption {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 5rem;
  display: flex;
  align-items: center;
  text-align: left;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.30), transparent);
}
#landing-carousel .caption-details {
  width: 40%;
  user-select: none;
}
#landing-carousel .caption-details button {
  margin-top: 0.7rem;
}

/* --- */
/*** --- INTRODUCTORY --- ***/
#landing-carousel .intro-cards {
  position: absolute;
  bottom: -2rem;
  right: 0;
  left: 0;
  margin: 0 auto;
  max-width: 100rem;
  padding: 0 2.5rem;
}
#landing-carousel .intro-cards-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
}
#landing-carousel .intro-card {
  background-color: #fff;
  display: flex;
  align-items: center;
  column-gap: 1.25rem;
  padding: 1rem;
  padding-left: 0.80rem;
  user-select: none;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%), 0 0 25px rgb(0 0 0 / 5%);
  transition: 0.25s;
}
#landing-carousel .intro-card:hover {
  transform: scale(1.05);
}
#landing-carousel .intro-icon {
  background-color: rgba(240, 66, 23, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.85rem;
  border-radius: 75px;
  font-size: 1.7rem;
}
#landing-carousel .intro-card path {
  fill: #f04217;
}
#landing-carousel .intro-card h5 {
  font-weight: bold;
}

/* --- Main Section --- */
/* --- Standout Projects --- */
#home h3 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
}
#home h3 span {
  color: #f04217;
}
#home section {
  margin: 5rem 0;
}
#home .standout-projects {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: 1.75fr 1fr;
  column-gap: 3rem;
}

/* .standout-projects
  .our-company
  .our-partners
  .our-company-img
  .our-company-details
  .services-card
  .customer-engagement {
  overflow-x: hidden !important;
} */
#home .dept-recovery {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../img/credit.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 2.5rem 2rem;
}
#home .dept-recovery p:first-of-type {
  font-size: larger;
  font-weight: bold;
  margin-bottom: 1rem;
}
#home .dept-recovery p:nth-child(2) {
  font-size: large;
  color: #f04217;
}
#home .dept-recovery p:nth-child(3) {
  font-size: large;
  max-width: 75%;
}
#home .dept-recovery button {
  margin-top: 2rem;
}

#home .customer-engagement {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../img/govern-agen.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2.5rem 2rem;
}
#home .customer-engagement p {
  font-size: x-large;
  font-weight: bold;
}
#home .customer-engagement button {
  margin-top: 1rem;
}

/* --- Services We Deliver --- */
#home .services-deliver {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
#home .services-deliver a h5,
#home .services-deliver a p {
  color: #000;
}
#home .services-card {
  background-color: #fff;
  position: relative;
  padding: 1rem;
  cursor: pointer;
  transition: 0.25s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05), 0 0 25px rgba(0, 0, 0, 0.05);
}
#home .services-card:hover {
  transform: scale(1.01);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05), 0 0 50px rgba(0, 0, 0, 0.05);
}
#home .services-card:hover button {
  background-color: #fff;
  color: #f04217;
}
#home .services-card h5 {
  font-weight: bold;
  margin: 0.85rem 0;
}
#home .services-card span {
  font-size: xx-large;
}
#home .services-card path {
  fill: #f04217;
}
#home .services-card button {
  margin-top: 1.75rem;
}
/* Mobile Dimension & Tablet */
@media only screen and (min-width: 769px) {
  #home .services-card {
    padding-bottom: 5.25rem;
  }
  .services-card-inner button {
    position: absolute;
    bottom: 20px;
  }
}

/* --- Our Company --- */
#home .our-company {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}
#home .our-company h3 {
  text-align: left;
}
#home .our-company h5 {
  font-weight: bold;
  margin-top: 1.75rem;
}
#home .our-company button {
  margin-top: 2.75rem;
}
#home .our-company-img {
  background-image: url("../img/ceo.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 25rem;
  position: relative;
}
.fidelity img{
  width: 100%;
  height: auto;
}
.modal-content {
  margin-top: 5rem; 
}

.fidelity-footer{
  display: block !important;
  background-color: #f04217;
  border: none;
  padding: 10px;
  cursor: pointer;
   font-size: 12px !important;
}

.fidelity-footer:hover{
  background-color: #fff;
  border: 1px solid #f04217;
  color:#f04217;
 

}
.modal-title {
   font-size: 14px !important;
}

/*** --- Media Queries --- ***/
/* Mobile Dimension */
@media only screen and (max-width: 480px) {
  #landing-carousel .intro-card {
    box-shadow: 0 0 10px rgb(0 0 0 / 5%), 0 0 25px rgb(0 0 0 / 5%);
  }
  #landing-carousel .carousel-item {
    height: 35vh !important;
  }
  #landing-carousel .carousel-caption {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.85),
      transparent
    );
  }
  #landing-carousel .caption-details {
    width: 90% !important;
  }
  #landing-carousel .intro-cards {
    position: static;
    padding: 0 2rem;
    margin-top: 1.5rem;
  }
  #landing-carousel .intro-cards-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
  }
  #landing-carousel .intro-card {
    column-gap: 1.15rem;
    padding-left: 1.5rem;
  }
  #home section {
    margin-top: 1.5rem;
  }
  #home .standout-projects {
    margin-top: 2.5rem;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
  }
  #home .customer-engagement p {
    font-size: larger;
  }
  #home .services-deliver {
    display: grid;
    grid-template-columns: 1fr !important;
    gap: 1.5rem;
  }
  #home .our-company {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 1.5rem;
  }
  #home .our-company-img {
    grid-row: 1;
  }
}

/* Mobile Dimension & Tablet */
@media only screen and (max-width: 768px) {
  #landing-carousel {
    margin-top: 4rem;
  }
  #landing-carousel .intro-cards {
    position: static;
    padding: 0 1.25rem;
    margin-top: 1.5rem;
  }
  #landing-carousel .intro-cards-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
  }
  #landing-carousel .intro-card {
    column-gap: 1.15rem;
    padding-left: 1.5rem;
  }
  #home .services-deliver {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
}

/* Tablet Dimension */
@media only screen and (max-width: 1024px) {
  #landing-carousel .carousel-item {
    height: 250px !important;
  }
  #landing-carousel .caption-details {
    width: 50%;
  }
  #landing-carousel .intro-cards {
    position: static;
    padding: 0 1.25rem;
    margin-top: 1.5rem;
}

#landing-carousel .intro-card {
    grid-column-gap: 1.15rem;
    column-gap: 1.15rem;
    padding-left: 1.5rem;
}
}

/* Extra Dimension */
@media only screen and (max-width: 1188px) {
  #landing-carousel .carousel-item {
    height: 55vh;
  }
  #landing-carousel .intro-cards {
    padding: 0 2rem;
  }

  #landing-carousel .intro-cards {
    position: static;
    padding: 0 1.25rem;
    margin-top: 1.5rem;
}

  #landing-carousel .intro-card {
    grid-column-gap: 1.15rem;
    column-gap: 1.15rem;
    padding-left: 1.5rem;
}
}
 

/* video-play-button */

.video-play-button {
  position: relative;
  z-index: 10;
  margin: 30px;
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  /* background: #fa183d; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ba1f24; 
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #fa183d;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken(#fa183d, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

#play-video {
  margin: auto;
  margin-top: 10rem;
}

.react-player {
  object-fit: cover !important;
}

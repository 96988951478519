#about section {
  margin-top: 3rem;
  margin-bottom: 5rem;
}
.about-intro {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.55)
    ),
    url("../img/outcess-photo/outcess8.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 5rem;
}
.about-intro-container {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 10rem 5rem;
}
.about-intro-content {
  color: #fff;
  width: 55%;
}
.about-intro-content h1 {
  font-weight: bold;
}
.about-intro-content button {
  margin-top: 1rem;
}
#about main h3 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 3.5rem;
}
#about main h4 span,
.about-intro-content h4 span {
  color: #f04217;
  font-size: xx-large;
}

.about-tcess{
  display: flex;
  justify-content: space-around;
}

/* --- About Outcess Details --- */
#about .about-tcess {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5rem;
}
#about .about-tcess h3 {
  text-align: left !important;
  margin-bottom: 1rem !important;
  padding: 2px;
  width: 100%;
}
#about .about-tcess h3 span {
  color: #f04217;
}
#about .tcss-yrs-active {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
#about .tcss-yrs-active div {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
#about .tcss-yrs-active h1 {
  color: #f04217;
  margin: 0;
  padding: 0;
  font-size: 8rem;
  font-weight: bold;
  line-height: 0.85;
}
#about .tcss-yrs-active p {
  font-weight: bold;
}
#about .tcss-yrs-active div p {
  font-size: 1.5rem;
}
#about .tcss-yrs-active > p {
  margin-top: 1rem;
  text-align: center;
  font-size: large;
}
#about .tcss-yrs-active > p span {
  color: #f04217;
}

/* --- About Cards --- */
#about .about-cards-cntnr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
}
#about .about-card {
  background-color: #fff;
  padding: 3rem 2rem;
  text-align: center;
  user-select: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05), 0 0 50px rgba(0, 0, 0, 0.05);
  transition: 0.15s;
}
#about .about-card:hover {
  transform: scale(1.05);
}
#about .about-card span {
  color: #838383;
  font-weight: bold;
}
#about .about-card h4 {
  color: #f04217;
  font-weight: bold;
  margin-top: 1.5rem;
}

/* --- Optimal Solutions --- */
#about .ptml-sltn ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}
#about .ptml-sltn li {
  list-style: none;
  color: #f04217;
}
#about .ptml-sltn h4 {
  color: #000;
  font-weight: bold;
}
#about .ptml-sltn h4 span {
  border-top: 3px solid #f04217;
}
#about .ptml-sltn p {
  color: #000;
}

/* --- Outcess Trends --- */
.tcss-trends {
  background-color: #124383;
  width: 100%;
}
.tcss-trends-container {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 4.5rem 5rem;
  color: #fff;
}
.tcss-trend-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
}
.tcss-trend {
  text-align: center;
}
.tcss-trend h1,
.tcss-trend h6 {
  font-weight: bold;
}

/* --- Media Query --- */
/* Mobile Screen */
@media only screen and (max-width: 480px) {
  .about-intro {
    margin-top: 4rem;
  }
  .about-intro-container {
    padding: 2rem;
  }
  .about-intro-content {
    width: 100%;
  }
  .tcss-trend-content {
    display: grid;
    grid-template-columns: 1fr !important;
    row-gap: 2rem;
  }
}

/* Small Dimension */
@media only screen and (max-width: 370px) {
  #about .tcss-yrs-active div p {
    font-size: 1rem !important;
  }
}

/* --- Mobile Dimension --- */
@media only screen and (max-width: 480px) {
  #about .tcss-yrs-active h1 {
    font-size: 7rem;
  }
  #about .tcss-yrs-active div p {
    font-size: 1.25rem;
  }
}
/* Extra Dimension */
@media only screen and (max-width: 768px) {
  #about .ptml-sltn ul {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
  }
  #about .about-tcess {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
   
}

/* Extra Dimension */
@media only screen and (max-width: 985px) {
  .tcss-trend-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }
  #about .about-cards-cntnr {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}

/* Extra Dimension */
@media only screen and (max-width: 1188px) {
  .about-intro-container {
    padding: 2rem;
  }
  .tcss-trends-container {
    padding: initial 2rem !important;
  }
}


  select { 
  width: 100% !important;
	border: 1px solid #D2D6DA;
		border-radius: 12px;
  display: inline-block;
  font: inherit; 
    padding: 16.5px  ; 
  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}


/* arrows */

select.classic {
  background-image:
    linear-gradient(45deg, transparent 50%, #005b90 50%),
    linear-gradient(135deg, #005b90 50%, transparent 50%),
    linear-gradient(to right, skyblue, skyblue);
  background-position: 
    97% 50%;
  background-size:
    4px 6px,
   12px 10px,
    1.7em 1.7em;
  background-repeat: no-repeat;
}


 

select.classic:focus {
  background-image:
    linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    linear-gradient(to right, gray, gray);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0;
}

select.round {
  background-image:
    linear-gradient(45deg, transparent 50%, #0275B1 50%),
    linear-gradient(135deg, #0275B1 50%, transparent 50%),
    radial-gradient(#e5f2f6 70%, transparent 72%);
  background-position:
    calc(100% - 20px) calc(1em + 10px),
    calc(100% - 15px) calc(1em + 10px),
    calc(100% - .5em) 1em;
  background-size:
    5px 5px,
    5px 5px,
    1.5em 1.5em;
  background-repeat: no-repeat;
}

select.round:focus {
  background-image:
    linear-gradient(45deg, #0275B1 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #0275B1 50%),
    radial-gradient(skyblue 70%, transparent 72%);
  background-position:
    calc(100% - 15px)1.5em,
    calc(100% - 20px) 1.5em,
    calc(100% - .5em) 1em;
  background-size:
    5px 5px,
    5px 5px,
    1.5em 1.5em;
  background-repeat: no-repeat;
  border-color: #005b90;
  outline: 0;
}

#ViewJob__Container{
 font-size: 15px;
 font-family: inherit;

}
#Job__sections{
    display: flex;
   
}
.details{
    width: 50%;
    border-radius: 5px;
    background-color: #fff;
    margin:0 20px 20px 20px;
}
.details__heading{
    background-color: #D8D8D8;
    padding: 15px;
    margin: 0;
    font-weight: bolder;  
    border-radius: 5px;
}
.details__content{
    padding: 15px;
    display: flex;
}
.fieldtitle ,.details__content label p,
.description__content label p{
    font-weight: bolder;
}
.details__content__right{
margin-left: 200px;
}
.subheading{
    margin-top: 10px !important;
    margin-bottom: 40px !important;
    color: grey !important;
    font-family: "Poppins" !important;
}
.subheading-right{
    margin-top: 10px !important;
    margin-bottom: 40px !important;
    color: #000 !important;
    font-family: "Poppins" !important;
}
.description{
    display: flex;
    justify-content: space-between;
    background-color: #fff;  
    padding: 15px;
}
#description__sections .subheading{
    margin-bottom: 0;
}
#description__sections,
#JobApplication__source{
    margin: 20px;
}
.JobApplication__source_form{
    display: flex;
    justify-content: space-between;
    padding:15px;
    background-color: #fff;
}
.Source__type,.Source__name{
    width: 30%;
}
.Source__input__container{
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding:15px ;
    margin-top: 30px;
}
.Source__input label p{
    font-weight: bolder;
    margin-bottom: 20px;
}
#Candidates__field__container{
   
    margin: 20px;
}
.profile__info__fields{
background-color: #EDF9FD;
}
.Candidates__field__title,.Education__details_title{
    background-color: #D9D9D9;
    padding: 20px;
     margin:20px 0 0px 0 ;
}
.profile__info__fields,.Education__details__field{
    display: flex;
    width: 50%;
    padding: 20px;
    text-align: left;
    justify-content: space-between;
}
#profile__info__container p,
.description p{
    margin-bottom: 20px;
}
.profile__info__fields +.profile__info__fields{
border-left: 2px solid grey ;

}
#profile__info__container{
    display: flex;
    justify-content: space-between;
    
}
.Education__details__field{
    border-right: 2px solid grey;   
}
#Education__info__container{
    display: flex;
   
}
.Required__field{
    margin-right: 80px;
}

@media only screen and (max-width: 1080px) {

    #Education__info__container{
    display: grid !important;
    grid-template-columns: 1fr !important; 
    }
    #ViewJob__sections,.details__content,
    .description,.JobApplication__source_form,.Source__input__container{
        display: flex;
       flex-direction: column;  
    }
    #Job__sections{
        display: flex;
        flex-direction: column;
    }
    .details,.description,.Source__type,.Source__name{
        width: auto;
        margin: 0;
    }
    .details__content__right{
        margin:0;
    }
    
   
}
@media only screen and (max-width: 720px) {

    #Education__info__container{
    display: grid !important;
    grid-template-columns: 1fr !important; 
    }
    #ViewJob__sections,.details__content,
    .description,.JobApplication__source_form,.Source__input__container{
        display: flex;
       flex-direction: column;  
    }
    #Job__sections{
        display: flex;
        flex-direction: column;
    }
    .details,.description,.Source__type,.Source__name{
        width: auto;
        margin: 0;
    }
    .details__content__right{
        margin:0;
    }
    
   
}
@media only screen and (max-width: 820px){
    #ViewJob__sections,.details__content,
    .description,.JobApplication__source_form,.Source__input__container{
        display: flex;
       flex-direction: column;  
    }
    #Job__sections{
        display: flex;
        flex-direction: column;
    }
    .details,.description,.Source__type,.Source__name{
        width: auto;
        margin: 0;
    }
    .details__content__right{
        margin:0;
    }
   
}
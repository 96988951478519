.gallery-page-img {
  background-color: #f8f8f8;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 5rem;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.55),
      rgba(0, 0, 0, 0.15)
    ),
    url(../img/foto.jpeg);
}

@media only screen and (max-width: 480px) {
  .gallery-page-img {
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 480px) {
  .ReactGridGallery_tile-viewport {
    width: 100% !important;
    max-width: 100% fit-content;
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
}

.gallery-page h1 {
  color: #fff;
  font-weight: bold;
  padding: 3rem 5rem;
  align-items: center;
}

#ReactGridGallery.ReactGridGallery {
  display: flex;
  justify-content: center !important;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10em;
}

.ReactGridGallery_tile {
  background: none !important;
  margin: 1px 15px !important;
}
.ReactGridGallery_tile img {
  background: none !important;
  margin-left: 0px !important;

  margin-left: 0px !important;
  width: 331px;
  height: 180px;
  object-fit: cover !important;
}
div#ReactGridGallery_tile-viewport {
  margin-left: 0px !important;
}
.ReactGridGallery_tile-viewport {
  background: none !important;

  margin-top: 25px;
  margin-left: 0px !important;
  width: 100px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  margin-left: 0px !important;
}

.ReactGridGallery_tile_overylay {
  color: none;
  background: none !important;
  margin-left: 0px !important;
}

#tile_overylay {
  display: flex;
  justify-content: center;
  background-color: blue;
  margin-left: 0px !important;
}

.gallery-img-backdrop {
  margin-left: 0px !important;
  max-width: 100%;
  height: auto;
}

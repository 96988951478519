#business-partners section {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  column-gap: 2.5rem;
  margin-top: 4.5rem;
  margin-bottom: 7rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #f04217;
}

#business-partners main section:last-of-type {
  border-bottom: none;
  margin-bottom: 5rem;
}

.biz-part-intro {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.85),
      rgba(0, 0, 0, 0.85)
    ),
    url("../img/customer-satisfaction.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 5rem;
}
.biz-part-intro-container {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 1.5rem 5rem;
}
.biz-part-intro-content {
  text-align: center;
  color: #fff;
}
.biz-part-intro-content h1 {
  font-weight: bold;
}
.biz-part-intro-content h1 span {
  color: #f04217;
}

.biz-part-info,
.biz-part-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.biz-part-info h4 {
  font-weight: bold;
}

.biz-part-info h4 span {
  color: #f04217;
}
.biz-part-info img {
  width: 15rem;
  object-fit: contain;
  margin-bottom: 2rem;
}
.biz-part-details h5 {
  font-weight: bold;
}

.biz-border {
  border-bottom: 1px solid #f04217;
}

/* --- Media Query --- */
/* Mobile Screen */
@media only screen and (max-width: 480px) {
  .biz-part-intro {
    margin-top: 4rem;
  }
}

/* Mobile & Tablet Dimension */
@media only screen and (max-width: 768px) {
  #business-partners section {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 5.5rem;
  }
  .biz-part-info img {
    width: 13.5rem;
    object-fit: contain;
    margin-bottom: 2rem;
  }
}

/* --- Extra Dimension (Large Screen) --- */
@media only screen and (max-width: 1188px) {
  .biz-part-intro-container {
    padding: 2rem;
  }
}

.biz-part-image {
  width: 250px !important;
  height: 100px !important;
  margin-bottom: 0px !important;
}

#Outcess-Clients-main {
  /* margin-top: 80px;  */
  margin-bottom: 100px;
}

.clients-img-main {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.55)
    ),
    url("../img/look-wall.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 5rem;
}

@media only screen and (max-width: 500px) {
  .clients-img-main {
    margin-top: 4rem;
  }
}

.clients-text-main h1 {
  color: #fff;
  font-weight: bold;
  max-width: 100rem;
  text-align: center;
}

.clients-text-main {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 3rem 5rem;
}

#clients-container {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  flex-wrap: wrap;
  object-fit: cover !important;
}

#card-clients {
  transition: transform 0.2s; /* Animation */
  border: none !important;
  box-shadow: none !important;
}

#card-clients:hover {
  transform: scale(1.3); /* (130% zoom)*/
}

@media only screen and (max-width: 480px) {
  .clients-img-main {
    margin-top: 4rem !important;
  }
}

#business-outsource section {
  margin-top: 3rem;
  margin-bottom: 5rem;
}
#business-outsource .bpo-intro {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.55)
    ),
    url("../img/chris.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 5rem;
}
.bpo-intr-containr {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 4rem 5rem;
}
.bpo-intr-contnt {
  color: #fff;
  width: 55%;
}
.bpo-intr-contnt h4 {
  font-weight: bold;
}
.bpo-intr-contnt h4 span {
  color: #f04217;
}
.bpo-intr-contnt button {
  margin-top: 1rem;
}

.bpo-core-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}
.bpo-core-card {
  display: grid;
  grid-template-rows: 1fr 1fr;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05), 0 0 50px rgba(0, 0, 0, 0.05);
  transition: 0.15s !important;
}
.bpo-core-card:hover{
  transform: scale(1.05) !important;
}


.bpo-core-card-img1 {
  background-image: url("../img/customer-care1.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.bpo-core-card-img2 {
  background-image: url("../img/customer-care2.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.bpo-core-card-img3 {
  background-image: url("../img/Data-analysis.jpg"); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.bpo-core-card-info {
  padding: 1rem;
}
.bpo-core-card-info h5 {
  font-weight: bold;
  color: #f04217;
}

.bpo-main-others {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}
.bpo-main-others-info {
  padding: 4rem 0;
}
.bpo-main-others-info li,
.bpo-main-others-info p span {
  font-weight: bold;
}
.bpo-main-others-info li {
  margin-bottom: 1.25rem;
}
.bpo-main-others-info li p {
  font-weight: normal;
}
.bpo-main-others-info h5 {
  font-weight: bold;
  margin: 0;
  color: #f04217;
}
.bpo-main-others-info button {
  margin-top: 1.25rem;
}
.bpo-main-others-img1 {
  background-image: url("../img/out1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 10px;
}
.bpo-main-others-ai {
  background-image: url("../img/ai-image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 10px;
}
.bpo-main-others-img2 {
  background-image: url("../img/book1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 10px;
}
.bpo-main-others-img3 {
  background-image: url("../img/looks.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 10px;
}

/* --- Media Query --- */
/* Mobile Screen */
@media only screen and (max-width: 480px) {
  #business-outsource .bpo-intro {
    margin-top: 4rem;
  }
  .bpo-core-cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .bpo-main-others {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
  }
  .bpo-main-others-info {
    padding: 0;
  }
  .bpo-main-others-img1,
  .bpo-main-others-img2,
  .bpo-main-others-img3 {
    grid-row: 1;
  }
}

/* Extra Dimension */
@media only screen and (max-width: 768px) {
  .bpo-intr-contnt {
    width: 100%;
  }
}

/* Extra Dimension */
@media only screen and (max-width: 1188px) {
  .bpo-intr-containr {
    padding: 0px;
  }
}

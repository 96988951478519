.case-metting-img {
  background-color: #f8f8f8;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 5rem;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.55),
      rgba(0, 0, 0, 0.15)
    ),
    url(../img/romain-outcess.jpg);
}

@media only screen and (max-width: 368px) {
   .card-container{
     display: flex;
     justify-content: space-evenly;
   }

  
}
@media only screen and (max-width: 568px) {
    

  .case-metting-img {
    margin-top: 4rem !important;
  }
}
 
.case-metting h1 {
  color: #fff;
  font-weight: bold;
}

.case-metting {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 8.5rem 5rem;
}

.case-categories {
  margin-top: 10px;
  height: 100%;
}

.case-title {
  font-weight: bold;
  font-size: 30px;
      font-size: 1.25rem !important;
}

.btn-case {
  margin-right: 20px;
  padding: 5px;
  margin-top: 15px;
  margin-right: 40px;
  border: 2px solid black;
  background: none;
  border-radius: 10px;
  color: #000;
}

.btn-case:hover {
  color: black;
  background-color: #fffefe;
  border-color: #f95738;
}
.btn-case:focus {
  border: 2px solid #e92a04;
  background-color: none;
  border-color: #f95738;
  font-weight: 600;
}

.card-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  padding-top: 20px;
  flex-wrap: wrap;
  border-radius: 0px !important;
  transition: 0.15s all ease-in-out;
  position: relative;
}

.categories-card {
  margin: 20px 0;
  border: 0px !important;
  border-radius: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px,
    rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  height: 470px !important;
  transition: transform 250ms;
}

.categories-card:hover {
  transform: translateY(-20px);
  box-shadow: 0 0 2px #c5c5c5;
}

.btn-primary {
  background-color: #f95738 !important;
  border-color: #f95738 !important;
  border-radius: 3px !important;
  position: absolute;
  bottom: 80px;
  outline: none !important;
  box-shadow: none !important;
  padding: 0.5rem;
  color: #fff;
}

.btn-primary:hover {
  border: 1px solid #e92a04 !important;
  background-color: #f8f8f8 !important;
  border-color: #e92a04 !important;
  color: #e92a04 !important;
}
.btn-primary:focus {
  border: 2px solid #e92a04 !important;
  background-color: #f8f8f8 !important;
  border-color: #e92a04 !important;
  box-shadow: none;
  color: #e92a04 !important;
}

.card-title {
  color: #f95738 !important; 
  font-weight: bold;
  
}
 

.Title-card-two {
  color: #000 !important;
  font-weight: 900;
  font-weight: bold;
}

.card-text {
  font-size: 14px;
  overflow: hidden;
}

.top {
  background-color: #f95738;
}

.btn-primary {
  outline: none !important;
  margin-bottom: -50px;
}

.image-fit {
  object-fit: cover;
  /* color: #e92a04; */
}


 
/* @media only screen and (max-width: 600px) {
 .card-title {
  color: #f95738 !important; 
  font-weight: bold;
    font-size: 1.3rem !important;
} 
.Title-card-two {
  color: #000 !important;
  font-weight: 900;
  font-weight: bold;
}
.card-text {
    font-size: 1em;
    overflow: hidden;
}
} */
@media only screen and (max-width: 360px) {
 .card-title {
  color: #f95738 !important; 
  font-weight: bold;
    font-size: 1rem !important;
} 
.Title-card-two {
  color: #000 !important;
  font-weight: 900;
  font-weight: bold;
}
.card-text {
    font-size: 0.9em;
    overflow: hidden;
}
}

#cards_landscape_wrap-2 a {
  text-decoration: none;
  outline: none;
}

#cards_landscape_wrap-2 .card-flyer .image-box {
  background: #ffffff;
  overflow: hidden;
}
#cards_landscape_wrap-2 .card-flyer .image-box img {
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  width: 100%;
  height: 200px;
  background-color: #c5c5c5;
}
#cards_landscape_wrap-2 .card-flyer:hover .image-box img {
  opacity: 0.7;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
}

#cards_landscape_wrap-2 .card-flyer {
  background: #ffffff;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
#cards_landscape_wrap-2 .card-flyer:hover {
  background: #fff;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
 

/* Loader 2 */

.loader2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 9em;
  margin: 5em;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.loader2,
.loader2:before,
.loader2:after {
  animation: 1s infinite ease-in-out;
}
.loader2:before,
.loader2:after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.loader-black2 {
  background-color: #333;
  display: flex;
  justify-content: center;
}

#loader2-1 {
  display: flex;
  justify-content: center;
}
.loader-12 {
  animation-name: loader1;
}
@keyframes loader1 {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}

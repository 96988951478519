.Quoteimg {
  display: block;  
  width: 80%;  
  max-width: 600px;  
  height: auto;  
  margin: 30px auto;  
  border-radius: 20px;  
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);  
  /* border: 5px solid #007acc;   */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; 
}

.Quoteimg:hover {
  transform: scale(1.1); /* Enlarges the image slightly on hover */
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3); /* Intensifies the shadow on hover */
}

	.request_quote_container{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

.Outcomes_main_text h1{
font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: #111633;
  margin-bottom: 20px;
}
 
.Outcomes_main_text p{
  font-size: 18px;
  line-height: 1.8;
  color: #555;
  margin-bottom: 15px;
  padding: 0 10px;
		width: 80%;
}

.Outcomes_main{
	display: grid;
	grid-template-columns: 1fr 1fr;
}

	.first_outcomes_container_main{
		display: flex;
		flex-direction: column;
	}
	.case_study_first_Solution_container_main_title{
		margin-top: 5rem;
	}
.case_study_first_Solution_container_main{
	display: grid;
	grid-template-columns: 1fr 2fr;
	gap: 50px;
}

.case_study_first_Solution_container_small {
  background-color: #f2f4f5;
  border-radius: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px; 
  margin: 50px auto;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.case_study_first_Solution_container_small h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: #111633;
  margin-bottom: 20px;
}

.case_study_first_Solution_container_small p {
  font-size: 18px;
  line-height: 1.8;
  color: #555;
  margin-bottom: 15px;
  padding: 0 10px;
}

.case_study_first_Solution_container_small_button {
  margin-top: 20px;
  padding: 15px 30px;
  background-color: #007acc;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.case_study_first_Solution_container_small_button:hover {
  background-color: #005fa3;
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}


/* .case_study_first_Solution_container_small{
  background-color: #f2f4f5;
		border-radius: 250px;
		display: flex;
		align-items: center;
		flex-direction: column;
}
.case_study_first_Solution_container_small h1{ 
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 45px;  
color: #111633; 
} */

/* Case Study Solution Container */
.case_study_first_Solution_container {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #eef6fc;
  border: 1px solid #007acc;
  border-radius: 8px;
}

 

.case_study_first_Solution_container .container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Heading styles */
.case_study_first_Solution_container h1, .case_study_first_Solution_container h2, .case_study_first_Solution_container h3 {
  color: #222;
  margin-bottom: 10px;
}

.case_study_first_Solution_container h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.case_study_first_Solution_container h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  border-bottom: 2px solid #007acc;
  padding-bottom: 5px;
}

.case_study_first_Solution_container h3 {
  font-size: 1.2rem;
  margin-top: 15px;
}

/* Paragraph styles */
.case_study_first_Solution_container p {
  margin: 10px 0;
}

/* Section styles */
.case_study_first_Solution_container .section {
  margin-bottom: 30px;
}

.case_study_first_Solution_container .section-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #007acc;
}

/* List styles */
.case_study_first_Solution_container ul {
  padding-left: 20px;
  margin: 10px 0;
}

.case_study_first_Solution_container li {
  margin-bottom: 5px;
}

/* Call-to-action button */
.case_study_first_Solution_container .button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007acc;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.case_study_first_Solution_container .button:hover {
  background-color: #005fa3;
}

/* Example usage */
.case_study_first_Solution_container .example {
  background: #f4f9ff;
  padding: 15px;
  border-left: 4px solid #007acc;
  margin-top: 20px;
  border-radius: 4px;
  font-style: italic;
}

	.case_study_first_Solution_container{

	}
.case_study_first_challenge{
	width:100% ;
	background-color: var(--clr-blue);
	
}
.case_study_first_challenge h1{ 
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 38px; 
display: flex;
align-items: center; 
color: var(--clr-white); 
}
.case_study_first_challenge p{   
color: var(--clr-white);  
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 26px; 
display: flex;
align-items: center; 
color: var(--clr-white);


}
	.business_icon_container_main_sub{
		display: grid;
		grid-template-columns: repeat(3,1fr);
		
	}

.business_icon_container_first h1{  
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 22px; 
display: flex;
align-items: center; 
color: #111633;  
width: 200px;
}

.business_icon_container_first p{   
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 26px; 
display: flex;
align-items: center; 
color: #1F1F1F;  
width: 250px;
}
.business_icon_container_first{
	display: flex;
	flex-direction: column;
	width: 300px;  
	margin-bottom:100px ;
	font-weight: 700;
}
.business_icon_container_main_sub{
	width: 100%;
}
	.business_icon_container_main{
		display: flex;
		gap: 30px;
	}
	.business_icon_container{  
width: 80px;
height: 80px; 
background: rgba(255, 255, 255, 0.002);
box-shadow: -1px 7px 15px rgba(0, 0, 0, 0.07);
border-radius: 50px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
	}

/* .case_title_container {
    
    background-image: url('../img/case-study-img01.png');
    background-size: cover;  
    background-position: center; 
    background-repeat: no-repeat;  
				height: 800px;
				display: flex;
				flex-direction: column; 
justify-content: center;
background-image: linear-gradient(to right, rgba(0, 0, 0, 0.30), transparent);
} */

.case_title_container {
  margin-top: 2rem;  
  background-image: 
      linear-gradient(to right, rgba(0, 0, 0, 0.30), transparent), 
      url('../img/case-study-img01.png');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
  height: 600px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
}

.case_title_container_one {
  margin-top: 5rem;  
  background-image: 
      linear-gradient(to right, rgba(0, 0, 0, 0.30), transparent), 
      url('../img/case-study-img01.png');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
  height: 500px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .case_title_container_one {
    /* height: 600px;  */
    background-size: cover; /* Ensure the background fits within smaller areas */
    margin-top: 2rem; /* Adjust top margin */
  }
}

@media screen and (max-width: 768px) {
  .case_title_container_one {
    /* height: 400px;   */
    background-position: top; /* Adjust background position for smaller devices */
  }
}
.case_title_container_two {
    margin-top: 5rem;  
    background-image: url('../img/caseStudy/airtelTitle.jpg');
    background-size: cover;  
    background-position: center; 
    background-repeat: no-repeat;  
				/* height: 800px; */
				/* display: flex;
				flex-direction: column; 
justify-content: center; */
}

.case_title_container4 {
  margin-top: 3rem;  
  background-image: url('../img/caseStudy/multichoiceNew.jpeg');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
      /* height: 800px; */
      display: flex;
      flex-direction: column; 
justify-content: center;
}

.case_title_container5 {
  margin-top: 3rem;  
  background-image: url('../img/caseStudy/lendSqrN.jpg');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
      height: 500px;
      display: flex;
      flex-direction: column; 
justify-content: center;
}

.case_title_container6 {
    margin-top: 4rem;  
  background-image: url('../img/caseStudy/cowryWise.jpeg');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;   
      display: flex;
      flex-direction: column; 
justify-content: center;
  height: 400px;
}

.case_title_container7 {
  
  background-image: url('../img/caseStudy/FinTech.jpg');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
      height: 500px;
      display: flex;
      flex-direction: column; 
justify-content: center;
}

.case_title_container8 {
  background-image: 
      linear-gradient(to right, rgba(0, 0, 0, 0.30), transparent), 
      url('../img/caseStudy/debtNN.jpg'); 
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
      height: 500px;
      display: flex;
      flex-direction: column; 
justify-content: center;
}

.case_title_container9 {
  
  background-image: url('../img/caseStudy/CscsN.jpg');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
      height: 500px;
      display: flex;
      flex-direction: column; 
justify-content: center;
}

.case_title_container10 {
  
  background-image: url('../img/caseStudy/mobiHealth.jpg');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
      height: 500px;
      display: flex;
      flex-direction: column; 
justify-content: center;
}
.case_title{
	width: 50%;   
display: flex;
flex-direction: row; 
justify-content: center;
height: 100%;
}

.case_title1{
	width: 50%;   
display: flex;
flex-direction: row; 
justify-content: center;
height: 100%;
color: white;
}
.case_title h1{  
color: var(--clr-white); 
text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
display: flex;
flex-direction: column;
}
.case_title p{   
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px; 
display: flex;
align-items: center; 
color: var(--clr-white);


}

.case_one_services{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center; 
}
.case_one_services_sub  {
	width: 35%;
	text-align: center;
	margin-top: 20px;
}
.case_one_services_sub h1{
 	display: flex; 
	justify-content: center;
	align-items: center; 
}




/* Hero Section */
.hero {
  position: relative;
  background: url('https://unsplash.com/photos/young-man-and-woman-in-a-car-rental-service-lease-contract-gsusX9fxaLE') no-repeat center/cover;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.hero .overlay {
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.hero p {
  font-size: 1.2rem;
}

/* Background Section */
.background {
  padding: 50px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f4f4f4;
}

.background h2 {
  font-size: 2.5rem;
}

.background p {
  font-size: 1rem;
  color: #555;
  width: 60%;
}

.background-image img {
  width: 35%;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Challenge Section */
.challenge {
  padding: 50px 20px;
  display: flex;
  justify-content: space-between;
  background: #fff;
}

.challenge-details {
  display: flex;
  width: 100%;
}

.challenge-text {
  width: 60%;
}

.challenge-text ul {
  list-style-type: none;
}

.challenge-text li {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 10px;
}

.challenge-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Approach Section */
.approach {
  padding: 50px 20px;
  background: #f9f9f9;
  text-align: center;
}

.approach h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.approach-details {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.approach-card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 30%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.approach-card:hover {
  transform: translateY(-10px);
}

.approach-card h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.approach-card ul {
  list-style-type: none;
  font-size: 1rem;
  color: #555;
}

.approach-card img {
  width: 100%;
  border-radius: 8px;
  margin-top: 20px;
}

/* Outcomes Section */
.outcomes {
  padding: 50px  0px;
  background: #005b90b8;
  color: var(--clr-white);
  text-align: center;
}

.outcomes h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.outcomes-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.outcome-item {
  width: 45%;
  background: #005b90;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  font-size: 1.2rem;
}

.outcomes-image img {
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
}


/* MultiChoiceCaseStudy.css */
.multiChoice-case-study {
  font-family: 'Roboto', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
}

.multiChoice-hero-section {
  background: linear-gradient(to right, #007bff, #00aaff);
  color: white;
  padding: 40px;
  text-align: center;
}

.multiChoice-main-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.multiChoice-subheading {
  font-size: 1.25rem;
  margin-bottom: 30px;
}

.multiChoice-hero-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.multiChoice-background-section {
  padding: 40px;
  background-color: #f9f9f9;
}

.multiChoice-section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.multiChoice-subsection-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.multiChoice-text {
  font-size: 1.125rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.multiChoice-challenge-section {
  padding: 40px;
  background-color: #f1f1f1;
}

.multiChoice-challenges-list {
  list-style-type: none;
  padding: 0;
}

.multiChoice-challenges-list li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.multiChoice-challenge-icon {
  font-size: 1.5rem;
  margin-right: 10px;
}

.multiChoice-challenge-text {
  font-size: 1.125rem;
}

.multiChoice-approach-section {
  padding: 40px;
  background-color: #fff;
}

.multiChoice-approach-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.multiChoice-approach-item {
  flex: 1;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.multiChoice-approach-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.multiChoice-approach-text {
  font-size: 1.125rem;
  line-height: 1.6;
}

.multiChoice-outcomes-section {
  padding: 40px;
  background-color: #f1f1f1;
}

.multiChoice-outcome-item {
  margin-bottom: 20px;
}

.multiChoice-outcome-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.multiChoice-outcome-text {
  font-size: 1.125rem;
  line-height: 1.6;
}




/* CustodianCaseStudy.css */
.custodian-case-study {
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  background-color: #f4f6f9;
}

.custodian-hero-section {
  background: linear-gradient(to right, #3b82f6, #6366f1);
  color: white;
  padding: 50px;
  text-align: center;
}

.custodian-main-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.custodian-subheading {
  font-size: 1.25rem;
  margin-bottom: 30px;
}

.custodian-hero-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.custodian-background-section {
  padding: 40px;
  background-color: #ffffff;
}

.custodian-section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.custodian-subsection-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.custodian-text {
  font-size: 1.125rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.custodian-challenge-section {
  padding: 40px;
  background-color: #f9fafb;
}

.custodian-challenges-list {
  list-style-type: none;
  padding: 0;
}

.custodian-challenges-list li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.custodian-challenge-icon {
  font-size: 2rem;
  margin-right: 15px;
}

.custodian-challenge-text {
  font-size: 1.125rem;
}

.custodian-approach-section {
  padding: 50px;
  background-color: var(--clr-white);
}

.custodian-approach-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.custodian-approach-item {
  flex: 1;
  background-color: #f3f4f6;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.custodian-approach-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.custodian-approach-text {
  font-size: 1.125rem;
  line-height: 1.6;
}

.custodian-outcomes-section {
  padding: 50px;
  background-color: #f9fafb;
}

.custodian-outcome-item {
  margin-bottom: 30px;
}

.custodian-outcome-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.custodian-outcome-text {
  font-size: 1.125rem;
  line-height: 1.6;
}

@media only screen and (max-width: 1024px) {
  .case_title_container8 {
    background-image: 
      linear-gradient(to right, rgba(0, 0, 0, 0.30), transparent), 
      url('../img/caseStudy/debtNN.jpg');  
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
      height: 400px;
      display: flex;
      flex-direction: column; 
justify-content: center;
}
  .case_title_container {
  margin-top: 2rem;  
  background-image: 
      linear-gradient(to right, rgba(0, 0, 0, 0.30), transparent), 
      url('../img/case-study-img01.png');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
  height: 400px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
}
  .case_title_container_one {
  margin-top: 4rem;  
  background-image: 
      linear-gradient(to right, rgba(0, 0, 0, 0.30), transparent), 
      url('../img/case-study-img01.png');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
  height: 400px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
}
  .case_title_container10 {
  
  background-image: url('../img/caseStudy/mobiHealth.jpg');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
      height: 300px;
      display: flex;
      flex-direction: column; 
justify-content: center;
}
  .case_title_container9 {
  
  background-image: url('../img/caseStudy/CscsN.jpg');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
      height: 400px;
      display: flex;
      flex-direction: column; 
justify-content: center;
}
  /* .case_title_container7 {
  
  background-image: url('../img/caseStudy/FinTech.jpg');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
      height: 300px;
      display: flex;
      flex-direction: column; 
justify-content: center;
} */
  .case_title_container6 {
    margin-top: 4rem;  
  background-image: url('../img/caseStudy/cowryWise.jpeg');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;   
      display: flex;
      flex-direction: column; 
justify-content: center;
  height: 300px;
}
  .case_title_container5 {
  margin-top: 3rem;  
  background-image: url('../img/caseStudy/lendSqrN.jpg');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat;  
      height: 300px;
      display: flex;
      flex-direction: column; 
justify-content: center;
}
.request_quote_container{
		display: grid;
		grid-template-columns: 1fr ;
	}
.multiChoice-challenge-section {
    padding: 20px; 
} 
.multiChoice-background-section {
    padding: 20px; 
}
.multiChoice-approach-section {
    padding: 20px; 
}
.multiChoice-outcomes-section {
    padding: 20px; 
}
.custodian-hero-section {  
    padding: 20px; 
}
.custodian-background-section {
    padding: 20px; 
}
.custodian-challenge-section {
    padding: 20px; 
}
.custodian-approach-section {
    padding: 20px; 
}
.custodian-approach-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
}
.custodian-outcomes-section {
    padding: 20px; 
}
.case_title h1 {
    color: var(--clr-white);
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    width: 100% !important;
}
.background p { 
    width: 100%;
}
.background {
    padding:  20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
}
.challenge {
    padding:   20px;  
}
.challenge-image{
  display: none;
}

.challenge-text li {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 10px;
    width: 100%;
}
.background-image img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.approach-details {
    display: grid;
   grid-template-columns: 1fr;
    gap: 40px;
}

.approach-card { 
    width: 100%; 
}
.approach {
    padding:   20px; 
}
.outcomes-details {
    display: grid;
    grid-template-columns: 1fr; 
}
.outcome-item {
    width:100%;
    background: #005b90;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px;
    font-size: 1.2rem;
}
.crrs-intr-cntnt {
    color: var(--clr-white);
    width: 100%;
}

.case_title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
}

.multiChoice-approach-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}
}
@media only screen and (max-width: 768px) {
.approach {
    padding: 0px; 
}
.challenge-text li {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 10px;
    width: 100%;
}
  .challenge {
    padding:   0px;  
}
  .background {
    padding:  0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
}
 .crrs-intr-cntnt {
    color: #fff;
    width: 100% !important;
}
.multiChoice-challenge-section {
    padding: 0px; 
} 
.multiChoice-background-section {
    padding: 0px; 
}
.multiChoice-approach-section {
    padding: 0px; 
}
.multiChoice-outcomes-section {
    padding:  0px; 
}
  .careers-intro { 
    margin-top: 2rem !important;
    width: 100%;
}
.custodian-hero-section {  
    padding:  0px; 
}
.custodian-background-section {
    padding: 0px; 
}
.custodian-challenge-section {
    padding:  0px; 
}
.custodian-approach-section {
    padding: 0px; 
}
.custodian-outcomes-section {
    padding: 0px; 
}
}


.BeWorkFrom-image-text h1 {
  color: #fff;
  font-weight: bold;
  width: 50%;
  margin: 5rem 4rem !important;
}
 
@media screen and (max-width: 600px) {
  .BeWorkFrom-image-text h1 {
    width: 100% !important;
    font-weight: bold !important;
    font-size: larger;
    text-align: center;
  }

  .BeWorkFrom-image-text p {
    display: none;
  }

}
 
.agent-text-phone h1 {
  margin-top: 5px;
  font-weight: bold;
  text-align: center;
}
.agent-text-phone h4 {
  font-weight: 400;
  text-align: center;
  font-size: 1.8rem;
}
.agent-text-phone p {
  font-weight: 400;
  text-align: center;
  font-size: 1em;
}

.work-home-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  justify-content: center !important;
  box-sizing: border-box;
  margin-top: 6rem;
  object-fit: cover;
}

.work-home-column {
  flex: 50%;
  max-width: 40%;
  padding: 0 4px;
  height: 500px;
  margin: 10px;
}
 

@media screen and (max-width: 1350px) {
  .container-video { position:relative; }
.container-video video {
    position:relative;
    z-index:0;
    width: 100%;
    height: auto !important;
    object-fit: cover;
}
  #first-img-left {
    width: 100%;
    height: 100% !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 40rem !important;
    
  }

  #second-img-right {
    display: none !important;
  }
  #third-img-left {
    display: none !important;
    object-fit: cover;
  }

  #four-img-card {
    display: none;
  }
  #first-img-left {
    max-width: 100% !important;
    margin: 1px !important;
    border-radius: 20px !important;
    
  }
}

@media screen and (max-width: 400px) {

    .glyphicon .glyphicon-tint {
font-size: small !important;
  }
  .flip-box {
    width: 400px !important;
    height: 300px;
    margin: 20px;
  } 
.container-video video {
    position:relative;
    z-index:0;
    width: 100%;
    height: auto !important;
    object-fit: cover;
}
  
}

@media screen and (max-width: 568px) {

       .features .feat span.glyphicon {
font-size: 2.5rem !important;
  }
      .agent-text-phone h4  {
font-size:1.8rem !important;
  }
      .agent-text-phone p  {
font-size: 1rem !important;
  }

  .BeWorkFrom-image-text p {
    display: none;
  }

}

@media screen and (max-width: 812px) {

       .features .feat span.glyphicon {
font-size: 2.5rem !important;
  }
      .agent-text-phone h4  {
font-size:1.8rem !important;
  }
      .agent-text-phone p  {
font-size: 1rem !important;
  }

  .BeWorkFrom-image-text p {
    display: none;
  }

  .container-video { position:relative; }
.container-video video {
    position:relative;
    z-index:0;
    width: 100%;
    height: auto !important;
    object-fit: cover;
}

.BeWorkFrom-image-text h1 {
  color: #fff;
  font-weight: bold;
  width: 50%;
  margin: 5rem 4rem !important;
}

}
@media screen and (max-width: 600px) {
      .features .feat span.glyphicon {
font-size: 2.3rem !important;
  }
      .agent-text-phone h4  {
font-size:1.5rem !important;
  }
      .agent-text-phone p  {
font-size: 0.8rem !important;
  }
.BeWorkFrom-image-text h1 {
  color: #fff;
  font-weight: bold; 
  margin: auto !important; 
 
}

  #first-img-left {
    width: 100% !important;
    height: 250px !important;
    max-height: 50%;
    border-radius: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

     .container-video { position:relative; }
.container-video video {
    position:relative;
    z-index:0;
    width: 100%;
    height: auto !important;
    object-fit: cover;
}
}

#first-img-left {
  max-width: 50%;
  height: 350px;
  margin-left: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#second-img-right {
  max-width: 30%;
  margin-right: 100px;
  margin-top: 100px;
  max-height: 260px;
  background-repeat: no-repeat;
  object-fit: cover;
}

.fit-img-column {
  width: 100%;
  height: 100%;
  background-color: #c5c5c5;
}

#third-img-left {
  max-width: 30%;
  height: 300px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

#four-img-card {
  max-width: 50%;
  margin-right: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#BeWorkFrom-image {
  background-color: #f8f8f8;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 4rem;
  width: 100%;
  max-width: 100%;
  height: auto;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.55),
      rgba(0, 0, 0, 0.15)
    ),
    /* url(../video/work-from-homen.mp4); */
}

 

#home-last-image {
  margin: 30px;
  display: flex;
  justify-content: space-between;
}

.home-ready-last-text {
  margin-top: 50px;
  font-weight: bold;
}

.card-title-icons1 {
  font-size: 100px;
  font-weight: lighter !important;
  color: #838383;
}
.card-title-icons2 {
  font-size: 80px;
  font-weight: lighter !important;
  margin-bottom: 30px;
  color: #838383;
}

.card-title-icons1 :hover {
  color: #f04217;
}
.card-title-icons2 :hover {
  color: #f04217;
}

#card-body-align {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 50px 0px;
}
#card-body-align p {
  font-size: large;
}

.card-borders {
  border: none !important;
}

/* Start Section Features */

.features {
  margin-top: 3em;
  text-align: center;
  display: flex !important;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.features .feat span.glyphicon {
  margin-top: 20px;
  font-size: 20px;
  color: #f04217 !important;
  font-size: 3em;
}

#card-contains {
  border-radius: 10px;
  box-shadow: 0 0 15px rgb(0 0 0 / 5%), 0 0 50px rgb(0 0 0 / 5%);
  background-color: #fff;
  margin: 10px;
  transition: transform 250ms;
}

#card-contains:hover {
  transform: translateY(-20px);
  box-shadow: 0 0 2px #c5c5c5;
}

 
.container-video { position:relative; }
.container-video video {
    position:relative;
    z-index:0;
    width: 100%;
    height: 80vh;
    object-fit: cover;
}
 

.h1-move{
  position: absolute;
  top: 50px;
}

 


/* #navbar.active {
    transform: translate(0px);
}  */

nav {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  transition: 0.25s;
  z-index: 999;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.05);
  transition: top 0.3s;
  
}

nav ul {
  list-style: none;
}

nav #logo img {
  width: 12rem;
  object-fit: contain;
}

nav .bg-screen-nav {
  display: flex;
  align-items: center;
}

nav .bg-nav-ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

nav .bg-screen-nav li {
  position: relative;
  font-size: 1.15rem;
  margin: 0 1rem;
  user-select: none;
  transition: 0.15s;
}

nav .bg-nav-ul > li {
  padding: 0.85rem 0;
  
}
nav .bg-nav-ul > li:hover {
  color: #f04217 !important;
}

a:hover {
  color: #f04217 !important;
}
nav .bg-screen-nav-title :hover {
  color: #f04217 !important;
}

nav .bg-screen-sub-menu {
  background-color: #fff;
  position: absolute;
  top: 4.35rem;
  left: -30px;
  padding: 0.5rem 1.25rem;
  border-radius: 3px;
  padding-right: 2rem;
  border-bottom: 4px solid #f04217;
  visibility: hidden;
  opacity: 0;
  transition: 0.25s;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.09);
}
nav .bg-screen-sub-menu.display {
  visibility: visible;
  opacity: 1;
  top: 3.35rem;
  transition: 0.4s;
}

nav .bg-screen-sub-menu:before {
  content: "";
  position: absolute;
  top: -7px;
  left: 25px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
}

nav .bg-screen-sub-menu li {
  margin: 0.5rem 0;
  width: max-content;
}

nav .bg-screen-sub-menu .active-link {
  color: #f04217;
}

nav .bg-screen-sub-menu li a {
  transition: 0.15s;
}
nav .bg-screen-sub-menu li a:hover {
  color: #f04217;
  transition: 0.15s;
}

nav button {
  border: 2px solid #f04217;
  font-weight: bold;
  font-size: 1rem;
  transition: 0.25s;
}
nav .bg-screen-nav button {
  background-color: #fff !important;
  color: #f04217 !important;
  padding: 0.25rem 1.5rem;
  margin-left: 2rem;
}
nav button:hover {
  background-color: #f04217 !important;
  color: #fff !important;
}

nav .sm-screen-nav {
  display: none;
}

nav .menu-btn {
  transition: all 0.4s ease-out;
}
nav .menu-btn .btn-line {
  width: 27px;
  height: 3px;
  margin-bottom: 6px;
  background-color: #3b3b3b;
  transition: all 0.25s ease-out;
}
nav .menu-btn.close .btn-line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}
nav .menu-btn.close .btn-line:nth-child(2) {
  opacity: 0;
}
nav .menu-btn.close .btn-line:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}

nav .menu-container {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 3.75rem;
  left: 100%;
  padding-top: 0.5rem;
  transition: 550ms;
  overflow-y: scroll;
}

nav .menu-container.display-menu {
  left: 0;
  transition: 350ms;
}

nav .menu-content {
  position: relative;
  padding: 1rem 1.3rem;
  padding-bottom: 5rem;
  overflow-y: scroll;
  overflow-x: auto; 
  height: 100vh;
}

nav .sm-screen-nav button {
  background-color: #f04217;
  color: #fff;
  padding: 0.55rem 1.35rem;
}

nav .sm-screen-nav .menu-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

nav .sm-screen-nav .menu-grid-details p {
  color: #f04217;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

nav .menu-grid-details li {
  margin: 0.25rem 0;
}

nav .menu-supports ul {
  margin-top: 2rem;
  margin-left: 1.3rem;
}

nav .menu-supports li {
  color: #f04217;
  list-style: disc;
}

/*** --- Media Queries --- ***/
/* Mobile Dimension */
@media only screen and (max-width: 480px) {
  nav {
    padding: 0 2rem !important;
  }
  nav #logo img {
    width: 10rem;
    object-fit: contain;
  }
}

/* Mobile & Tablet Dimension */
@media only screen and (max-width: 768px) {
  nav {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    height: 4rem !important;
  }
  nav .bg-screen-nav {
    display: none;
  }
  nav .sm-screen-nav {
    display: block;
  }
}

/* Tablet Dimension */
@media only screen and (min-width: 480px) and (max-width: 768px) {
  nav {
    padding: 0 3.5rem !important;
  }
}

/* Extra Dimension */
@media only screen and (max-width: 1188px) {
  nav {
    padding: 0 2rem;
 
  }
}
 
@media only screen and (max-width: 1400px) {
   
  nav .bg-nav-ul > li {
    padding: 0;
    font-size: 16px;
    white-space: nowrap;
  }

nav button {

  font-size: 0.8rem;
 
}
nav .bg-screen-nav button {
  background-color: #fff !important;
  color: #f04217 !important;
  padding: 0.2rem 2rem;
  margin-left: 1rem;
  
}
}

 
.message {
  display: flex !important;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin: 10em 10em 0 0;
  padding: 0 2em;
}
.message h1 {
  color: #3698dc;
  font-size: 3vw !important;
  font-weight: 400;
}
.message p {
  color: #262c34;
  font-size: 1.3em;
  font-weight: lighter;
  line-height: 1.1em;
}
.light {
  position: relative;
  top: -36em;
}
 
.light span:first-child {
  display: block;
  height: 6px;
  width: 150px;
  position: absolute;
  top: 5em;
  left: 20em;
  background: #fff;
  border-radius: 3px;
  /*   transform: rotate(40deg); */
}
.light span:nth-child(2) {
  display: block;
  height: 6px;
  width: 200px;
  position: absolute;
  top: 6em;
  left: 19em;
  background: #fff;
  border-radius: 3px;
  /*   transform: rotate(40deg); */
}
.light span:nth-child(3) {
  display: block;
  height: 6px;
  width: 100px;
  position: absolute;
  top: 7em;
  left: 24em;
  background: #fff;
  border-radius: 3px;
  /*   transform: rotate(40deg); */
}
 
.use-desktop {
  font-weight: 400;
  color: #3698dc;
  border: 1px solid white;
  height: 3.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 25px;
  line-height: 1.1em;
  font-size: 5vw;
}


 


.expired-container{
	display: flex;
}
.expired-right{
	width: 50%;
	height: 100vh; 
}
.expired-left{
	width: 50%;
	height: 100vh; 
	 background-image: url('../../assets/svg/hero-learning-home.svg');
		background-size: cover;
}

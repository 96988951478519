#ideology section {
  margin-top: 3rem;
  margin-bottom: 8.5rem;
}
#ideology .idlgy-intro {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.55)
    ),
    url("../img/togerther.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 5rem;
}
.idlgy-intro-container {
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  padding: 1.5rem 5rem;
}
.idlgy-intro-content {
  color: #fff;
  text-align: center;
}
.idlgy-intro-content h1 {
  font-weight: bold;
}

#ideology .vsn-mssn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
}
#ideology .vsm-mssn-cntnt {
  text-align: center;
}
#ideology .vsm-mssn-cntnt span {
  font-size: 3rem;
  color: #f04217;
}
#ideology .vsm-mssn-cntnt h4 {
  font-weight: bold;
}
#ideology .vsm-mssn-cntnt div {
  display: flex;
  justify-content: center;
}
#ideology .vsm-mssn-cntnt p {
  width: 80%;
}

#ideology .idlgy-others {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5rem;
}
#ideology .idlgy-othrs-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4.5rem 0;
}
#ideology .idlgy-othrs-info h4 {
  font-weight: bold;
  margin-bottom: 1.25rem;
}
#ideology .idlgy-othrs-info p {
  width: 75%;
}
#ideology .idlgy-othrs-img1 {
  background-image: url("../img/peop5.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
}
#ideology .idlgy-othrs-img2 {
  background-image: url("../img/pass.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
}
#ideology .idlgy-othrs-img3 {
  background-image: url("../img/peop.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
}
#ideology .idlgy-othrs-img4 {
  background-image: url("../img/peop3.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
}
#ideology .idlgy-othrs-img5 {
  background-image: url("../img/peop4.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
}

#ideology .idlgy-othrs-img6 {
  background-image: url("../img/peop2.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
}
/* --- Media Query --- */
/* Mobile Screen */
@media only screen and (max-width: 480px) {
  #ideology .idlgy-intro {
    margin-top: 4rem;
  }
  #ideology .vsn-mssn {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 4rem;
  }
  #ideology .idlgy-others {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 0.5rem;
  }
  #ideology .idlgy-othrs-info {
    grid-row: 2;
  }
  #ideology .idlgy-othrs-img {
    grid-row: 1;
  }
  #ideology .idlgy-othrs-info {
    padding: 1rem 0;
  }
  #ideology .idlgy-othrs-info p {
    width: 100%;
  }
  #ideology section {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  #ideology .idlgy-othrs-info h4 {
    margin-bottom: 1rem;
  }
}

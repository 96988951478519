.subpages-main {
  margin-top: 100px !important;
  margin-bottom: 0px !important;
  margin-right: 30px !important;
  margin-left: 30px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.sub-image {
  object-fit: cover !important;
}

.card-img-top {
  object-fit: cover !important;
  border-radius: 10px !important; 
   box-shadow: 0 0 15px rgb(0 0 0 / 0%), 0 0 50px rgb(0 0 0 / 0%);
  outline: none !important;
}

.subpages-card {
  background-color: #fff;
  border-radius: 0px !important;
  border: 0px;
}

.subpages-title {
  padding: 30px !important ;
  width: 50%;
  float: right;
}

.subpages-text-para {
  font-size: 1.05rem;
  font-weight: 400 !important ;
}

.card-text-third {
  margin-top: 20px;
  font-weight: bold !important ;
  font-size: 1.2rem;
}

.subpages-title-one {
  font-size: 30px !important;
  font-weight: 500 !important ;
}

#img-rapper{
  height: 400px; 
  
  
}

.subpages-title-two {
  font-size: 25px !important;
  font-weight: 400 !important ;
  color: #000 !important;
}

.subpages-btn {
  background-color: #f8f8f8 !important;
  font-size: 20px !important;
  color: #f04217;
}

.subpages-nav-footer {
  padding: 15px;
  margin: 5px 0px;
  width: 100%;
  background-color: rgb(253, 252, 252);
  color: #f04217;
}
.subpages-nav-link {
  display: flex !important;
  justify-content: space-between !important;
  color: #fff;
}

.nav-link {
  color: #f8f8f8;
}

@media only screen and (max-width: 800px) {
  .subpages-card {
    background-color: #fff;
    border-radius: 0px !important;
    box-shadow: none !important;
    border: 0px;
  }

  .subpages-title {
    padding: 30px !important ;

    width: 50%;
    float: right;
  }

  /* .subpages-main{
 box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;     
       
} */
}

/* --- Pagination --- */
.Pagination {
  display: flex !important;
  z-index: -99 !important ;
  box-shadow: none !important;
  outline: none !important;
}

.paginationBttns {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  font-size: medium;
  font-weight: bold;
  justify-content: space-evenly !important;
  color: #000 !important;
  margin: 25px;
  padding: 10px;
  z-index: -99 !important;
  box-shadow: none !important;
  outline: none !important;
}
.paginationBttns a {
  color: #000 !important;
  padding: 5px 10px;
  cursor: pointer;
  box-shadow: none !important;
  z-index: -1 !important;
  text-decoration: none;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.paginationDisabled a:hover {
  /* color: blue !important; */
  box-shadow: none !important;
  border: none !important;
}
a.previousBttn,
a.nextBttn {
  color: #000 !important;
  font-size: 30px;
  box-shadow: none !important;
  outline: none !important;
}
a.previousBttn :hover,
a.nextBttn :hover {
  color: #e2522e;
  box-shadow: none !important;
}
a.previousBttn {
  margin-right: 80vw;
}

.paginationDisabled a {
  color: #6e6e6e !important;
  cursor: default !important;
  box-shadow: none !important;
  outline: none !important;
}
.previousBttn i,
.nextBttn i {
  font-size: 0.7rem;
  box-shadow: none !important;
}
.previousBttn i {
  padding-right: 5px;
  box-shadow: none !important;
  outline: none !important;
}
.nextBttn i {
  padding-left: 5px;
  box-shadow: none !important;
  outline: none !important;
}

.paginationActive a {
  background-color: #e2522e !important;
  color: #fff !important;
  border: 1px solid #e2522e;
  box-shadow: none !important;
  outline: none !important;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  display: none !important;
}

.sub-btn-move {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 50px;
  margin-bottom: -80px;
  z-index: 1 !important;
  background-color: white;
  position: relative;
  outline: none !important;
}
.sub-btn-move btn:hover {
  border: 1 solid #e2522e !important ;
  color: #f80c0c;
  outline: none !important;
}

@media only screen and (max-width: 800px) {
  a.previousBttn {
    margin-right: 50vw;
  }
}

@media only screen and (max-width: 900px) {
  a.previousBttn {
    margin-right: 50vw;
  }
}
@media only screen and (max-width: 500px) {
  a.previousBttn {
    margin-right: 50vw !important;
  }
  .paginationBttns a {
    font-size: 30px !important;
    box-shadow: none !important;
  }
}
@media only screen and (max-width: 400px) {
  .paginationBttns a {
    font-size: 30px !important;
    box-shadow: none !important;
  }
}

.text-muted a{
  color: #e2522e !important;
  font-weight: 50px;
  font-size: 1em;
  cursor: pointer;
}

.studies-next-prev {
  display: flex;
  justify-content: space-between;
  margin: 50px 50px;
  box-shadow: none !important; 
  outline: none !important;
}

.span-bold {
  font-weight: bold;
}

.card-body {
  overflow-x: hidden !important;
}
#sub-card-fade-left {
  overflow-x: hidden !important;
  margin: 2rem;
}
.card-body #sub-card-fade-left {
  overflow-x: hidden !important;
}

.subpages-main {
  overflow-x: hidden !important;
}
.subpages-card {
  overflow-x: hidden !important;
}
